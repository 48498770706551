<template>
  <div class="all-page">
    <!-- Header导航栏部分 -->
    <header>
      <ManagementHeaderBar :index="'/management'" />
    </header>
    <!-- 筛选栏+表格部分 -->
    <section class="main-container">
      <ManagementIdeaFilter class="custom-filter" @filterUpdate="handleFilterUpdate" />
      <div class="card-container">
        <div class="title-bar">
          <div class="title-text">{{ $t("ideasManagement") }}</div>
          <div style="display: flex; gap: 2rem">
            <el-input v-model="searchText" :placeholder="$t('searchAnything')" :prefix-icon="Search"
              @input="handleSearchTextInput" clearable="true" class="search-bar" style="--el-color-primary: #f36633">
            </el-input>
            <el-button class="export-btn" @click="exportExcel">{{
              $t("exportExcel")
            }}</el-button>
          </div>
        </div>

        <el-table :data="tableData" style="
            margin-top: 1rem;
            padding-bottom: 6rem;
            --el-color-primary: #f36633;
          " @sort-change="sortChange">
          <el-table-column prop="Idea.Title" :label="$t('idea')">
          </el-table-column>
          <el-table-column prop="Idea.CreatedBy" :label="$t('CreatedBy')">
            <template #default="scope">
              <div style="
                  display: flex;
                  align-items: center;
                  gap: 0.2rem;
                  color: #868693;
                ">
                <el-avatar :src="'data:image/jpeg;base64,' + findDataById(scope.row.Idea.IdeaId)!.User.Avatar"
                  :size="24" class="custom-avatar"></el-avatar>{{ findDataById(scope.row.Idea.IdeaId)!.User.UserName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column props="Category.CategoryName" :label="$t('category')" width="120">
            <template #default="scope">
              <el-select v-model="findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName" disabled
                placeholder="Select">
                <el-option :key="findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName"
                  :label="findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName"
                  :value="findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="Tag" :label="$t('tag')">
            <template #default="scope">
              <div style="color: #868693">
                <el-tag v-for="(tag, index) in findDataById(scope.row.Idea.IdeaId)!.Tag" :key="index" type="info"
                  style="margin-right: 0.5rem;margin-bottom:0.5rem">
                  {{ tag.TagName }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Idea.Status" :label="$t('status')" width="150">
            <template #default="scope">
              <el-dropdown @command="handleStatusChange">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 8 9" fill="none"
                    style="margin-right: 6px">
                    <circle cx="4" cy="4.5" r="4" :fill="getStatusColor(
                      findDataById(scope.row.Idea.IdeaId)!.Idea.Status
                    )
                      " />
                  </svg>
                  <span :style="{
                    color: getStatusColor(
                      findDataById(scope.row.Idea.IdeaId)!.Idea.Status
                    ),
                  }">{{
                    getStatusText(
                      findDataById(scope.row.Idea.IdeaId)!.Idea.Status
                    )
                  }}</span>

                  <el-icon style="margin-left: 6px; color: #868693">
                    <CaretBottom />
                  </el-icon>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="(value, index) in Array.from(
                      { length: 4 },
                      (v, i) => i + 1
                    )" :key="index" class="status-dropdown-item" :style="{
                      color: getStatusColor(value),
                    }" :command="{
                      StatusCode: value,
                      IdeaId: findDataById(scope.row.Idea.IdeaId)!.Idea.IdeaId,
                      IdeaTitle: findDataById(scope.row.Idea.IdeaId)!.Idea
                        .Title,
                      IdeaOwnerId: findDataById(scope.row.Idea.IdeaId)!.Idea.Owner,
                      IdeaOrignStatusCode: findDataById(scope.row.Idea.IdeaId)!.Idea.Status
                    }" :disabled="value <= findDataById(scope.row.Idea.IdeaId)!.Idea.Status">
                      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 8 9" fill="none">
                        <circle cx="4" cy="4.5" r="4" :fill="getStatusColor(value)" />
                      </svg>
                      <span style="margin-left: 6px">{{
                        getStatusText(value)
                      }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column prop="CommentCount" :label="$t('comments')" sortable="custom">
            <template #default="scope">
              <el-badge :value="findDataById(scope.row.Idea.IdeaId)!.Idea.CommentCount"
                @click="showCommentDialog(scope.row.Idea.IdeaId)"></el-badge></template>
          </el-table-column>
          <el-table-column prop="CollectCount" :label="$t('Collects')" sortable="custom">
            <template #default="scope">
              <el-badge :value="findDataById(scope.row.Idea.IdeaId)!.Idea.CollectCount"></el-badge></template>
          </el-table-column>
          <el-table-column prop="LikeCount" :label="$t('Likes')" sortable="custom">
            <template #default="scope">
              <el-badge :value="findDataById(scope.row.Idea.IdeaId)!.Idea.LikeCount"></el-badge></template>
          </el-table-column>
          <el-table-column prop="Idea.CreatedOn" :label="$t('createTime')">
            <template #default="scope">
              <span style="color: #868693">{{
                formatTimestamp(
                  findDataById(scope.row.Idea.IdeaId)!.Idea.CreatedOn
                )
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="Idea.ModifiedOn" :label="$t('lastUpdate')">
            <template #default="scope">
              <span style="color: #868693">{{
                formatTimestamp(
                  findDataById(scope.row.Idea.IdeaId)!.Idea.ModifiedOn
                )
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="Action" :label="$t('action')">
            <template #default="scope">
              <el-icon @click="() => handleRowDelete(scope.$index, findDataById(scope.row.Idea.IdeaId)!.Idea.IdeaId)"
                style="cursor: pointer">
                <Delete />
              </el-icon> </template></el-table-column>
        </el-table>
        <!-- Footer 页码部分-->
        <footer class="footer">
          <el-pagination small background layout="prev, pager, next" :page-count="totalPageCount"
            @current-change="handleCurrentPageChange" style="--el-color-primary: #f36633" />

          <!-- Footer 选择个数部分 -->
          <el-select class="select-number" v-model="pageNum" @change="handlePageNumChange">
            <el-option v-for="item in [10, 20, 30, 40, 50]" :key="item" :label="item + '/page'" :value="item"
              style="--el-color-primary: #f36633"></el-option>
          </el-select>
        </footer>
      </div>
    </section>

    <el-dialog v-model="dialogTableVisible" :modal-append-to-body="false" :append-to-body="false" :center="true">
      <template #header>
        <span class="dialog-header">{{ $t("commentsDetail") }}</span>
      </template>
      <div v-for="(item, index) in commentData" :key="index" class="comment-item">
        <div class="userinfo-bar">
          <div class="userinfo">
            <el-avatar :src="'data:image/jpeg;base64,' + item.User.Avatar" :size="40"></el-avatar>
            <div style="margin-left: 1rem">
              <div class="username">{{ item.User.UserName }}</div>
              <div class="createtime">
                {{ formatTimeAgo(item.Comment.CreatedOn) }}
              </div>
            </div>
          </div>
          <span class="reply-button" @click="handleReplyClick(item.Comment.CommId)">{{ $t("reply") }}</span>
        </div>
        <div class="comment-content">
          <div class="root-comment">{{ item.Comment.Content }}</div>
        </div>
        <div class="child-comment">
          <div class="child-comment-content" v-for="(reply, reply_index) in item.Reply" :key="reply_index">
            <div class="userinfo-bar" style="padding: 0">
              <div class="userinfo">
                <el-avatar :src="'data:image/jpeg;base64,' + reply.User.Avatar" :size="40"></el-avatar>
                <div style="margin-left: 1rem">
                  <div class="username">
                    {{ reply.User.UserName }}
                    <span class="createtime">
                      <el-icon>
                        <CaretRight />
                      </el-icon>
                    </span>
                    {{ reply.PareCommUser.UserName }}
                  </div>
                  <div class="createtime">
                    {{ formatTimeAgo(reply.Comment.CreatedOn) }}
                  </div>
                </div>
              </div>
              <span class="reply-button" @click="
                handleReplyClick(item.Comment.CommId, reply.Comment.CommId)
                ">{{ $t("reply") }}</span>
            </div>
            <div style="margin-left: 3.5rem; margin-top: 1rem">
              {{ reply.Comment.Content }}
            </div>
          </div>
          <div style="display: flex; padding-top: 1rem; padding-bottom: 1rem" v-if="item.Reply && item.commentCard">
            <el-avatar :src="'data:image/jpeg;base64,' + getUserInfo()!.graphUserInfo!.avatar" :size="40"></el-avatar>
            <div style="width: 100%">
              <el-input v-model="commentText" :autosize="{ minRows: 3 }" type="textarea" placeholder="Please input"
                style="margin-left: 1rem; --el-color-primary: #f36633" />
              <div style="display: flex; margin-left: 1rem">
                <el-button class="submit-btn" @click="
                  handleChildCommentSubmit(
                    commentData[0].Comment.IdeaId,
                    item.Reply[0]
                      ? item.Reply[0].Comment.RootCommId
                      : item.Comment.CommId,
                    item.Comment.Content
                  )
                  ">{{ $t("submitLower") }}</el-button>
                <el-button class="cancel-btn" color="white
      " @click="handleCancelClick">{{ $t("cancel") }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="commentData.length == 0" style="text-align: center">
        {{ $t("noCommentData") }}
      </div>
      <template #footer>
        <div class="footer-input">
          <el-input v-model="commentContent" :placeholder="$t('replyMsg')" style="--el-color-primary: #f36633">
            <template #append>
              <div style="
                  color: white;
                  min-width: 3rem;
                  cursor: pointer;
                  --el-color-primary: #f36633;
                " :style="{
                  background: commentContent.length > 0 ? '#F36633' : '#aeaeb7',
                }">
                <el-icon @click="handleRootCommentSubmit(currentIdeaId)">
                  <Promotion />
                </el-icon>
              </div>
            </template>
          </el-input>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
.all-page {
  display: flex;
  min-height: 100vh;
  overflow-y: hidden;
  flex-direction: column;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-grow: 1;
}

.card-container {
  flex: 4;
  padding-top: 0.5rem;
  box-sizing: border-box;
}

.custom-filter {
  flex: 0.6;
  background-color: #f6f6f6;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #f36633;
  border-color: #f36633;
}

:deep(.el-collapse) {
  --el-collapse-header-bg-color: #f6f6f6;
  --el-menu-bg-color: #f6f6f6;
  --el-collapse-content-bg-color: #f6f6f6;
}

.title-text {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.status-dropdown-item {
  display: flex;
  align-items: center;
}

.el-badge {
  --el-badge-bg-color: #f36633 !important;
  --el-color-danger: #f36633 !important;
}

.dialog-header {
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.25rem;
}

.userinfo-bar {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-left: 2rem;
  padding-right: 2rem;
}

.reply-button {
  color: #868693;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  cursor: pointer;
}

.userinfo {
  display: flex;
}

.username {
  color: #484851;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3125rem;
}

.createtime {
  color: #aeaeb7;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
}

.el-avatar {
  border: 2px solid rgba(174, 174, 183, 0.2);
  flex-shrink: 0;
}

.child-comment {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  background: #f6f6f6;
  margin-left: 5.5rem;
  padding-right: 2rem;
  margin-bottom: 1rem;
}

.child-comment-content {
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: rgba(201, 201, 207, 0.5) 1px solid;
}

.root-comment {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.comment-content {
  padding-left: 5.5rem;
  padding-right: 2rem;
}

.footer-input {
  width: 100%;
  padding: 1rem 1.5rem;
  background: #f6f6f6;
  box-sizing: border-box;
}

:deep(.el-dialog__footer) {
  padding: 0;
}

:deep(.el-input-group__append) {
  padding: 0;
}

:deep(.el-input__wrapper) {
  border-radius: 0.5rem;
}

.select-number {
  position: fixed;
  right: 2rem;
  width: 10rem;
  --el-color-primary: #f36633;
}

.search-bar {
  width: 21.625rem;
  height: 2.5rem;
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
}

.submit-btn {
  color: white;
  margin-top: 1rem;
  text-align: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  height: 2rem;
  padding: 0rem 1rem;
  border-radius: 0.375rem;
  font-family: GSK Precision;
  --el-button-bg-color: #f36633 !important;
  --el-button-border-color: #f36633 !important;
  --el-button-hover-bg-color: #376f7b !important;
  --el-button-hover-text-color: white !important;
  --el-button-hover-border-color: #376f7b !important;
  --el-button-active-bg-color: #376f7b !important;
  --el-button-active-border-color: #376f7b !important;
}

.cancel-btn {
  margin-top: 1rem;
  color: #484851;
  text-align: center;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  height: 2rem;
  padding: 0rem 1rem;
  border-radius: 0.375rem;
  font-family: GSK Precision;
  --el-button-border-color: #c9c9cf !important;
  --el-button-hover-border-color: #c9c9cf !important;
  --el-button-active-border-color: #c9c9cf !important;
  --el-button-hover-text-color: #484851 !important;
  --el-button-active-text-color: #484851 !important;
}

.comment-item:not(:last-child) {
  border-bottom: rgba(201, 201, 207, 0.5) 1px solid;
}

.comment-item:not(:first-child) {
  padding-top: 1rem;
}

.footer {
  width: 100%;
  position: fixed;
  padding-left: 2rem;
  bottom: 0;
  display: flex;
  z-index: 999;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.el-button.is-disabled {
  color: var(--el-button-disabled-text-color) !important;
}

.export-btn {
  color: #fff;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  --el-button-bg-color: #f36633 !important;
  --el-button-border-color: #f36633 !important;
  --el-button-hover-bg-color: #376f7b !important;
  --el-button-hover-text-color: white !important;
  --el-button-hover-border-color: #376f7b !important;
  --el-button-active-bg-color: #376f7b !important;
  --el-button-active-border-color: #376f7b !important;
}
</style>

<script lang="ts">
import ManagementHeaderBar from "@/components/ManagementHeaderBar.vue";
import ManagementIdeaFilter from "@/components/ManagementIdeaFilter.vue";
import { ref, onMounted, inject, reactive } from "vue";
import { AxiosInstance } from "axios";
import { getStatusText } from "@/utils/getStatusText";
import { getStatusColor } from "@/utils/getStatusColor";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import {
  CaretBottom,
  Delete,
  Promotion,
  Search,
  CaretRight,
} from "@element-plus/icons-vue";
import { getUserInfo } from "@/state/UserInfo";
import XLSX from "xlsx";
export default {
  name: "IdeaManagementView",
  components: {
    ManagementHeaderBar,
    ManagementIdeaFilter,
  },
  setup() {
    interface ManagementData {
      Idea: Idea;
      IsCollect: boolean;
      IsLike: boolean;
      User: User;
      PageCount: number;
      Category: Category;
      Tag: Tag[];
    }

    interface Category {
      CategoryName: string;
    }
    interface Tag {
      TagName: string;
    }

    interface Idea {
      CollectCount: number;
      CommentCount: number;
      CreatedBy: number;
      CreatedOn: string;
      Description: string;
      IdeaId: number;
      LikeCount: number;
      ModifiedBy: number;
      ModifiedOn: string;
      Owner: number;
      PlaygroundCreatedBy: number;
      PlaygroundCreatedOn: string;
      PlaygroundModifiedBy: number;
      PlaygroundModifiedOn: string;
      State: number;
      Status: number;
      SubmittedOn: string;
      Title: string;
      BannerImgUrl: string;
    }

    interface User {
      Avatar: string;
      UserId: number;
      UserName: string;
    }

    interface Command {
      IdeaId: number;
      StatusCode: number;
      IdeaTitle: string;
      IdeaOwnerId: number;
      IdeaOrignStatusCode: number;
    }

    interface Comment {
      CommId: number;
      Content: string;
      CreatedBy: number;
      CreatedOn: string;
      IdeaId: number;
      LikeCount: number;
      ModifiedBy: number;
      ModifiedOn: string;
      PareCommId: number;
      State: number;
      RootCommId: number;
    }

    interface CommentData {
      isLike: boolean;
      Comment: Comment;
      User: User;
      Reply: Reply[];
      commentCard: boolean;
    }

    interface Reply {
      Comment: Comment;
      User: User;
      PareCommUser: PareCommUser;
    }

    interface PareCommUser {
      Authority: number;
      Email: string;
      IdeaCount: number;
      MudId: string;
      PageCount: number;
      PendingAdmit: boolean;
      RowCount: number;
      UserId: number;
      UserName: string;
    }

    const dialogTableVisible = ref(false);
    const commentCard = ref(false);
    const commentText = ref("");
    const tableData = ref<ManagementData[]>([]);
    const commentData = reactive<CommentData[]>([]);
    const commentContent = ref("");
    const pageNum = ref(10);
    const currentPage = ref(1);
    const checkedStatus = ref<string[]>([]);
    const checkedCategory = ref<string[]>([]);
    const checkedTag = ref<string[]>([]);
    const checkedOwner = ref<string[]>([]);
    const searchText = ref("");
    const currentCommId = ref(0);
    const createTime = ref("");
    const lastUpdateTime = ref("");
    const currentIdeaId = ref(0);
    const totalPageCount = ref(5);
    const orderColumn = ref("");
    const order = ref("");
    const { t } = useI18n();
    onMounted(async () => {
      await getIdeaList();
    });
    /* eslint-disable */
    const formatTimestamp = inject("formatTimestamp") as (
      timestamp: string
    ) => string;
    const formatTimeAgo = inject("formatTimeAgo") as (
      timestamp: string
    ) => string;
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    /**
     * Retrieves the list of ideas from the server and rearranges them into columns.
     * The resulting list is assigned to both the `ideaList` and `checkedIdeaList` variables.
     * @function
     * @name getIdeaList
     * @returns {void}
     */
    const getIdeaList = () => {
      const requestData = {
        UserId: getUserInfo()!.graphUserInfo!.UserId,
        PageSize: pageNum.value,
        OrderColumn: orderColumn.value,
        Order: order.value,
        PageIndex: currentPage.value,
        ...(checkedStatus.value ? { Statuses: checkedStatus.value } : {}),
        ...(createTime.value ? { CreateTime: createTime.value } : {}),
        ...(lastUpdateTime.value
          ? { LastUpdateTime: lastUpdateTime.value }
          : {}),
        ...(checkedOwner.value ? { Owners: checkedOwner.value } : {}),
        ...(searchText.value ? { SearchText: searchText.value } : {}),
        ...(checkedCategory.value ? { Categories: checkedCategory.value } : {}),
        ...(checkedTag.value ? { Tags: checkedTag.value } : {}),
      };

      console.log("requestData:", requestData);

      return axiosInstance
        .request({
          url: "api/Idea/GetIdeaList",
          data: requestData,
          method: "post",
        })
        .then((res) => {
          console.log(res);
          tableData.value = res.data.Data;
          if (
            tableData.value[0] &&
            "PageCount" in tableData.value[0] &&
            tableData.value[0].PageCount
          ) {
            totalPageCount.value = tableData.value[0].PageCount;
          } else {
            totalPageCount.value = 1;
          }
          console.log("Get Idea List Successfully.", tableData.value);
        });
    };

    const handleStatusChange = async (command: string | number | Command) => {
      if (
        typeof command === "object" &&
        "IdeaId" in command &&
        "StatusCode" in command &&
        "IdeaTitle" in command &&
        "IdeaOwnerId" in command &&
        "IdeaOrignStatusCode" in command
      ) {
        await updateIdeaStatus(
          command.IdeaId,
          command.StatusCode,
          command.IdeaTitle,
          command.IdeaOwnerId,
          command.IdeaOrignStatusCode
        );
        await getIdeaList();
        // const selectRow = tableData.find(
        //   (data: ManagementData) =>
        //     data.Idea.IdeaId === (command as Command).IdeaId
        // );
        // if (selectRow) {
        //   selectRow.Idea.Status = (command as Command).StatusCode;
        // }
      }
    };

    const updateIdeaStatus = async (
      IdeaId: number,
      Status: number,
      IdeaTitle: string,
      IdeaOwnerId: number,
      IdeaOrignStatusCode: number
    ) => {
      return axiosInstance
        .request({
          url: "api/Idea/UpdateIdeaStatus",
          data: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
            IdeaId: IdeaId,
            Status: Status,
            IdeaTitle: IdeaTitle,
            IdeaOwnerId: IdeaOwnerId,
            IdeaOrignStatus: IdeaOrignStatusCode,
          },
          method: "post",
        })
        .then((res) => {
          console.log("Update Successfully.", res);
        });
    };

    const handleRowDelete = async (index: number, IdeaId: number) => {
      await deleteIdea(IdeaId);
      await getIdeaList();
    };

    const deleteIdea = async (IdeaId: number) => {
      ElMessageBox.confirm(`${t("warningmsg")}`, `${t("warning")}`, {
        confirmButtonText: `${t("confirm")}`,
        cancelButtonText: `${t("cancel")}`,
        type: "warning",
      })
        .then(() => {
          return axiosInstance
            .request({
              url: "api/Idea/DeleteIdea",
              data: {
                UserId: getUserInfo()!.graphUserInfo!.UserId,
                ideaId: IdeaId,
              },
              method: "post",
            })
            .then((res) => {
              ElMessage({
                type: "success",
                message: `${t("deletesuccess")}`,
                offset: 100,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: `${t("deletecancel")}`,
            offset: 100,
          });
        });
    };
    const popDialog = (data: any) => {
      console.log(data);
    };
    const findDataById = (IdeaId: number) => {
      return tableData.value.find((data) => data.Idea.IdeaId === IdeaId);
    };

    const handleFilterUpdate = (filter: any) => {
      // status filter
      checkedStatus.value = filter.checkedStatus;
      // owner filter
      checkedOwner.value = filter.checkedOwner;
      // category filter
      checkedCategory.value = filter.checkedCategory;
      // tag filter
      checkedTag.value = filter.checkedTag;
      // createtime filter
      if (filter.createTime != null) {
        createTime.value = filter.createTime;
      }
      // lastupdate filter
      if (filter.lastUpdateTime != null) {
        lastUpdateTime.value = filter.lastUpdateTime;
      }
      getIdeaList();
    };

    const handleSearchTextInput = (value: string) => {
      searchText.value = value;
      getIdeaList();
    };

    const handleReplyClick = (
      parentCommId: number,
      childCommId: number | null = null
    ) => {
      commentData.forEach(function (comment: CommentData) {
        comment.commentCard = false;
        if (comment.Comment.CommId === parentCommId) {
          comment.commentCard = true;
        }
      });
      if (childCommId) {
        currentCommId.value = childCommId;
      } else {
        currentCommId.value = parentCommId;
      }
    };

    const handleCancelClick = () => {
      commentData.forEach(function (comment: CommentData) {
        comment.commentCard = false;
      });
      commentText.value = "";
    };

    const showCommentDialog = (IdeaId: number) => {
      getCommentList(IdeaId);
      dialogTableVisible.value = true;
      currentIdeaId.value = IdeaId;
    };

    const getCommentList = (IdeaId: number) => {
      axiosInstance
        .request({
          url: "api/Comment/GetCommentList",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
            IdeaId: IdeaId,
            PageSize: 500,
          },
          method: "get",
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.Data.length === 0) {
            commentData.splice(0, commentData.length);
            return;
          }
          let tempCommentData = res.data.Data;
          tempCommentData.forEach(function (comment: CommentData) {
            comment.commentCard = false;
          });
          Object.assign(commentData, tempCommentData);
        });
    };

    const handleRootCommentSubmit = (IdeaId: number) => {
      addComment(IdeaId, 0, 0, commentContent.value);
    };

    const handleChildCommentSubmit = (
      IdeaId: number,
      RootCommId: number,
      ParentCommentContent: string
    ) => {
      addComment(
        IdeaId,
        currentCommId.value,
        RootCommId,
        commentText.value,
        ParentCommentContent
      );
    };

    const addComment = (
      IdeaId: number,
      ParentCommId: number,
      RootCommId: number,
      commentContent1: string,
      ParentCommentContent?: string
    ) => {
      axiosInstance
        .request({
          url: "api/Comment/AddComment",
          data: {
            pareCommId: ParentCommId,
            ideaId: IdeaId,
            content: commentContent1,
            RootCommId: RootCommId,
            IdeaOwnerId: findDataById(IdeaId)?.Idea.Owner,
            IdeaTitle: findDataById(IdeaId)?.Idea.Title,
            createdBy: getUserInfo()!.graphUserInfo!.UserId,
            ParentCommentContent: ParentCommentContent,
          },
          method: "post",
        })
        .then((res) => {
          console.log("Add Comment Successfully.", res);
          getCommentList(IdeaId);
          commentContent.value = "";
          commentText.value = "";
        });
    };

    const handleCurrentPageChange = (val: number) => {
      currentPage.value = val;
      getIdeaList();
    };

    const handlePageNumChange = () => {
      getIdeaList();
    };

    const sortChange = (e: any) => {
      if (e.order) {
        orderColumn.value = e.prop;
        order.value = e.order.toLowerCase() === "ascending" ? "ASC" : "DESC";
      } else {
        orderColumn.value = "";
        order.value = "";
      }
      console.log(orderColumn.value);
      getIdeaList();
    };

    const exportExcel = async () => {
      let wb = XLSX.utils.book_new();
      let sheetData = ExportIdeaList();
      let sheet = XLSX.utils.json_to_sheet(await sheetData);
      XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
      XLSX.writeFile(wb, "DigitalInnovationHubIdeaData.xlsx");
    };
    const ExportIdeaList = () => {
      return axiosInstance
        .request({
          url: "/api/Idea/ExportIdeaList",
          method: "get",
        })
        .then((res) => {
          return JSON.parse(res.data.Data);
        });
    };

    return {
      tableData,
      dialogTableVisible,
      commentContent,
      pageNum,
      currentPage,
      searchText,
      commentCard,
      commentText,
      commentData,
      checkedStatus,
      checkedOwner,
      currentIdeaId,
      totalPageCount,
      getStatusColor,
      getStatusText,
      handleRowDelete,
      handleStatusChange,
      popDialog,
      findDataById,
      handleFilterUpdate,
      handleSearchTextInput,
      handleReplyClick,
      handleCancelClick,
      showCommentDialog,
      handleRootCommentSubmit,
      handleChildCommentSubmit,
      addComment,
      formatTimestamp,
      formatTimeAgo,
      handleCurrentPageChange,
      handlePageNumChange,
      getUserInfo,
      sortChange,
      exportExcel,
      CaretBottom,
      Delete,
      Promotion,
      Search,
      CaretRight,
    };
  },
};
</script>
