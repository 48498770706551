import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import BrowseView from "../views/BrowseView.vue";
import SubmitView from "../views/SubmitView.vue";
import IdeaDetailView from "../views/IdeaDetailView.vue";
import ProfileView from "../views/ProfileView.vue";
import IdeaManagementView from "@/views/IdeaManagementView.vue";
import UserPoolView from "@/views/UserPoolView.vue";
import IdeaStatisticsView from "@/views/IdeaStatisticsView.vue";
import WecomView from "@/views/WecomView.vue";
import PlaygroundView from "@/views/PlaygroundView.vue";
import CategoryManagementView from "@/views/CategoryManagementView.vue";
import TagManagementView from "@/views/TagManagementView.vue";

import { getUserInfo } from "@/state/UserInfo";
import { getSasToken } from "@/state/getSasToken";

const routes = [
  {
    path: "/wecom",
    name: "wecom",
    component: WecomView,
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/playground",
    name: "playground",
    component: PlaygroundView,
    meta: { requiresAuth: true },
  },
  {
    path: "/browse",
    name: "browse",
    component: BrowseView,
    meta: { requiresAuth: true },
  },
  {
    path: "/submit",
    name: "submit",
    component: SubmitView,
    meta: { requiresAuth: true },
  },
  {
    path: "/ideas/:id",
    name: "ideas",
    component: IdeaDetailView,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: { requiresAuth: true },
  },
  {
    path: "/management",
    name: "management",
    component: IdeaManagementView,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/userpool",
    name: "userpool",
    component: UserPoolView,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/stastics",
    name: "ideaStatisticsView",
    component: IdeaStatisticsView,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/category",
    name: "categoryManagementView",
    component: CategoryManagementView,
  },
  {
    path: "/tag",
    name: "tagManagementView",
    component: TagManagementView,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

interface TokenResult {
  access_token: string;
  expires_in: number;
  ext_expires_in: number;
  expire_date: number;
  scope: string;
  token_type: string;
}

function isTokenExpired(tokenResult: TokenResult): boolean {
  const currentTime = new Date().getTime();
  return currentTime > tokenResult.expire_date;
}
router.beforeEach((to, from, next) => {
  const userInfo = getUserInfo();
  const isWeCom = /MicroMessenger/i.test(navigator.userAgent);
  const wecomUserId = userInfo?.wecom_userid;
  const hasRedirected = to.query.state;
  if (hasRedirected) {
    next();
  } else if (isWeCom && (!wecomUserId || wecomUserId === "")) {
    const weComRedirectUrl =
      "https://open.weixin.qq.com/connect/oauth2/authorize";
    const appId = "wxea15d639e2155786";
    const redirectUri = encodeURIComponent(
      "https://hub-dev.gsk-rd-apps.com/wecom"
    );
    const responseType = "code";
    const scope = "snsapi_base";
    const state = "redirected";

    const fullRedirectUrl = `${weComRedirectUrl}?appid=${appId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${state}#wechat_redirect`;

    window.location.href = fullRedirectUrl;
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Get the user info from sessionStorage
    getSasToken();
    // Check if the access token exists and is not expired
    if (
      userInfo?.tokenResult!.access_token &&
      !isTokenExpired(userInfo.tokenResult)
    ) {
      if (to.matched.some((record) => record.meta.requiresAdmin)) {
        if (userInfo?.graphUserInfo!.authority == 1) {
          next(() => {
            window.scrollTo(0, 0);
          });
        } else {
          // If the user is not an admin, stop the navigation
          next({
            path: "/",
          });
        }
      } else {
        next(() => {
          window.scrollTo(0, 0);
        });
      }
    } else {
      // If the access token does not exist or is expired, redirect to the login page
      next({
        path: "/login",
        query: { nextUrl: to.fullPath },
      });
    }
  } else {
    next(() => {
      window.scrollTo(0, 0);
    });
  }
});

export default router;
