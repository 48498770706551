import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/logo.png'
import _imports_1 from '@/assets/icons/caret-down.svg'
import _imports_2 from '@/assets/icons/log-out.svg'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "left-container" }
const _hoisted_3 = { class: "right-container" }
const _hoisted_4 = { class: "dropdown-container" }
const _hoisted_5 = { class: "dropdown-link" }
const _hoisted_6 = { class: "avatar-pop-container" }
const _hoisted_7 = { class: "avatar-pop-name" }
const _hoisted_8 = { class: "logout-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        style: {"cursor":"pointer"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogoClick && _ctx.handleLogoClick(...args)))
      }),
      _createVNode(_component_el_menu, {
        "default-active": _ctx.index,
        mode: "horizontal",
        ellipsis: false,
        class: "custom-menu",
        router: "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, { index: "/userpool" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("userPool")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "/management" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("ideasManagement")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "/category" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("categoryMgt")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "/tag" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("tagMgt")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "/stastics" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("ideaStatistics")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["default-active"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_dropdown, { onCommand: _ctx.handleLanguageChange }, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownOptions, (dropdownOption, dropdownOptionIndex) => {
                  return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: dropdownOptionIndex,
                    command: {
                  label: dropdownOption.label,
                  value: dropdownOption.value,
                }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(dropdownOption.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["command"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("button", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.dropdownValue), 1),
              _cache[2] || (_cache[2] = _createElementVNode("img", { src: _imports_1 }, null, -1))
            ])
          ]),
          _: 1
        }, 8, ["onCommand"])
      ]),
      _createVNode(_component_el_popover, {
        width: 160,
        "popper-style": "box-shadow: 0px 2px 10px 0px rgba(72, 72, 81, 0.10);padding:0",
        placement: "bottom-end"
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_el_avatar, {
            src: 'data:image/jpeg;base64,' + _ctx.userInfo?.graphUserInfo!.avatar,
            class: "custom-avatar",
            style: {"margin-left":"1.5rem"},
            size: 40
          }, null, 8, ["src"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              style: {"cursor":"pointer","padding-top":"1rem","padding-bottom":"1rem"},
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleProfileClick && _ctx.handleProfileClick(...args)))
            }, [
              _createVNode(_component_el_avatar, {
                src: 'data:image/jpeg;base64,' + _ctx.userInfo?.graphUserInfo!.avatar
                ,
                class: "custom-avatar",
                size: 64
              }, null, 8, ["src"]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.userInfo?.graphUserInfo!.displayName), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_el_button, {
                class: "logout-btn",
                onClick: _ctx.handleLogoutClick,
                style: {"--el-color-primary":"#f36633"}
              }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                    src: _imports_2,
                    style: {"margin-right":"0.75rem"}
                  }, null, -1)),
                  _createTextVNode(_toDisplayString(_ctx.$t("logout")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}