/* eslint-disable */
// if (process.env.NODE_ENV == "production") {
//   console.log = console.warn = console.error = console.info = function () {};
// }

import { ComponentOptionsBase, ComponentProvideOptions, ComponentPublicInstance, createApp, Ref } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/styles/element-ui-reset.scss";
import ElementPlus, { LoadingParentElement } from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { createI18n, useI18n } from "vue-i18n";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import axios, { AxiosInstance } from "axios";
import enMessages from "@/i18n/en";
import zhMessages from "@/i18n/zh";
import jpMessages from "@/i18n/jp";
import { formatTimeAgo } from "@/utils/formatTimeAgo";
import { formatTimestamp } from "./utils/formatTimestamp";
import { getUserInfo, saveUserInfo } from "@/state/UserInfo";
import { ElLoading } from 'element-plus';


// 导入样式
import "element-plus/dist/index.css";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const i18n = createI18n({
  legacy: false,
  locale: "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages: { en: enMessages, zh: zhMessages, jp: jpMessages }, // set locale messages
});

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

if (getUserInfo() && getUserInfo()?.tokenResult) {
  axiosInstance.interceptors.request.use((config) => {
    const token = getUserInfo()!.tokenResult!.access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
}

let loadingInstance: { close: any; setText?: (text: string) => void; removeElLoadingChild?: () => void; handleAfterLeave?: () => void; vm?: ComponentPublicInstance<{}, {}, {}, {}, {}, {}, {}, {}, false, ComponentOptionsBase<any, any, any, any, any, any, any, any, any, {}, {}, string, {}, {}, {}, string, ComponentProvideOptions>, {}, {}, "", {}, any>; $el?: HTMLElement; originalPosition?: Ref<string, string>; originalOverflow?: Ref<string, string>; visible?: Ref<boolean, boolean>; parent?: Ref<LoadingParentElement, LoadingParentElement>; background?: Ref<string, string>; svg?: Ref<string, string>; svgViewBox?: Ref<string, string>; spinner?: Ref<string | boolean, string | boolean>; text?: Ref<string, string>; fullscreen?: Ref<boolean, boolean>; lock?: Ref<boolean, boolean>; customClass?: Ref<string, string>; target?: Ref<HTMLElement, HTMLElement>; beforeClose?: Ref<(() => boolean) | undefined, (() => boolean) | undefined> | undefined; closed?: Ref<(() => void) | undefined, (() => void) | undefined> | undefined; };
axiosInstance.interceptors.request.use(
  function (config: any) {
    const noLoadingEndpoints = ['api/Tag/GetTagList'];
    const shouldHideLoading = noLoadingEndpoints.some(endpoint => config.url.endsWith(endpoint));
    if (!shouldHideLoading) {
      loadingInstance = ElLoading.service({
        lock: true,
        text: i18n.global.t('loading'),
        background: 'rgba(0, 0, 0, 0.7)',
      });
    }
    return config;
  },
  function (error) {
    if (loadingInstance) loadingInstance.close();
    return Promise.reject(error);
  }
);



axiosInstance.interceptors.response.use(
  function (response) {
    if (loadingInstance) loadingInstance.close();
    // Response
    const userInfo = getUserInfo();
    if (userInfo && userInfo.tokenResult) {
      const currentTime = new Date().getTime();
      const expires_time =
        (userInfo!.tokenResult!.expire_date - currentTime) / 3600;
      console.log(expires_time);
      if (expires_time <= 200) {
        RefreshJwtToken();
      }
    }
    return response;
  },
  function (error) {
    if (loadingInstance) loadingInstance.close();
    if (error.code == "ERR_NETWORK") {
      router.push("/login");
    }
    console.error(error);
  }
);


const RefreshJwtToken = () => {
  return axiosInstance
    .request({
      url: "api/Token/RefreshJwtToken",
      data: {
        token: getUserInfo()?.tokenResult!.access_token,
      },
      method: "post",
    })
    .then((res) => {
      const userInfo = getUserInfo();
      if (userInfo && userInfo.tokenResult) {
        userInfo.tokenResult = res.data.data;
        saveUserInfo(userInfo);
      }
      console.log("refresh", userInfo);
    });
};

app.config.globalProperties.$formatTimeAgo = formatTimeAgo;
app.config.globalProperties.$formatTimestamp = formatTimestamp;
app.provide("formatTimeAgo", formatTimeAgo);
app.provide("formatTimestamp", formatTimestamp);

app.provide("axiosInstance", axiosInstance);
app.use(ElementPlus);
app.use(router);
app.use(i18n);
app.component("QuillEditor", QuillEditor);
app.mount("#app");
