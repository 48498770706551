import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "banner-container" }
const _hoisted_4 = { class: "banner-text" }
const _hoisted_5 = { class: "form-container" }
const _hoisted_6 = {
  style: {"position":"relative"},
  class: "input-container"
}
const _hoisted_7 = { class: "container-title" }
const _hoisted_8 = { style: {"background-color":"white"} }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  class: "custom-quilleditor",
  style: {"position":"relative"}
}
const _hoisted_12 = { class: "container-title" }
const _hoisted_13 = { style: {"background-color":"white"} }
const _hoisted_14 = { class: "category-container" }
const _hoisted_15 = { class: "container-title" }
const _hoisted_16 = { style: {"background-color":"white"} }
const _hoisted_17 = { class: "tag-container" }
const _hoisted_18 = { class: "container-title" }
const _hoisted_19 = { style: {"background-color":"white"} }
const _hoisted_20 = { class: "attachment-container" }
const _hoisted_21 = { class: "container-title" }
const _hoisted_22 = { style: {"background-color":"white"} }
const _hoisted_23 = { class: "elupload-container" }
const _hoisted_24 = { class: "el-upload__text" }
const _hoisted_25 = { class: "warning-container" }
const _hoisted_26 = { class: "btn-row" }
const _hoisted_27 = { class: "footer" }
const _hoisted_28 = { class: "footer_bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_QuillEditor = _resolveComponent("QuillEditor")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_FooterBar = _resolveComponent("FooterBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_HeaderBar, { index: '/submit' })
    ]),
    _createElementVNode("section", _hoisted_3, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "rectangle" }, null, -1)),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("submitYourIdea")), 1)
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_input, {
          modelValue: $setup.form_title,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form_title) = $event)),
          class: "title-container",
          style: {"--el-color-primary":"#f36633"}
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "required-symbol" }, "  * ", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("title")) + "  ", 1)
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: "bannerimg-container",
        style: _normalizeStyle({
        'justify-content': $setup.bannerImgUrl ? 'start' : 'center',
      })
      }, [
        ($setup.bannerImgUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: $setup.bannerImgUrl + '?' + $setup.getSasTokenFromCache(),
              class: "banner-img"
            }, null, 8, _hoisted_9))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: "bannerimg-tips",
            innerHTML: _ctx.$t('imgCondition')
          }, null, 8, _hoisted_10),
          _createVNode(_component_el_upload, {
            action: $setup.uploadFileUrl,
            data: $setup.BannerForm,
            "before-upload": $setup.beforeUpload,
            accept: $setup.acceptTypes,
            "file-size-limit": $setup.fileSizeLimit,
            "on-success": $setup.handleBannerUploadSuccess,
            "show-file-list": false,
            headers: { Authorization: `Bearer ` + $setup.getUserInfo()!.tokenResult!.access_token },
            style: {"--el-color-primary":"#f36633"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                class: "bannerimg-btn",
                color: "#f6f6f6",
                style: {"--el-button-hover-border-color":"#c9c9cf","--el-button-active-border-color":"#c9c9cf"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.bannerImgUrl ? _ctx.$t("chooseImgAgain") : _ctx.$t("chooseImg")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["action", "data", "before-upload", "accept", "file-size-limit", "on-success", "headers"])
        ])
      ], 4),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "required-symbol" }, "  * ", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("description")) + "  ", 1)
          ])
        ]),
        _createVNode(_component_QuillEditor, {
          theme: "snow",
          style: {"width":"100%"},
          content: $setup.form_description,
          "onUpdate:content": _cache[1] || (_cache[1] = ($event: any) => (($setup.form_description) = $event)),
          contentType: "html"
        }, null, 8, ["content"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "required-symbol" }, "  * ", -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("category")) + "  ", 1)
          ])
        ]),
        _createVNode(_component_el_select, {
          modelValue: $setup.categoryId,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.categoryId) = $event)),
          placeholder: "Select",
          size: "large",
          style: {"width":"80%","margin-top":"30px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.categoryOptions, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.Category.CategoryId,
                label: item.Category.CategoryName,
                value: item.Category.CategoryId,
                style: {"--el-color-primary":"#f36633"}
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", null, "  " + _toDisplayString(_ctx.$t("tag")) + "  ", 1)
          ])
        ]),
        _createVNode(_component_el_select_v2, {
          modelValue: $setup.value1,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.value1) = $event)),
          options: $setup.tagOptions,
          placeholder: _ctx.$t('selectMsg'),
          size: "large",
          style: {"width":"80%","margin-top":"30px","--el-color-primary":"#f36633","vertical-align":"middle"},
          "allow-create": "",
          filterable: "",
          multiple: "",
          clearable: "",
          remote: "",
          "remote-method": $setup.GetTagList,
          onChange: $setup.handleTagChange,
          "collapse-tags": "",
          "max-collapse-tags": "10"
        }, null, 8, ["modelValue", "options", "placeholder", "remote-method", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("span", null, "  " + _toDisplayString(_ctx.$t("attachments")) + "  ", 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_component_el_upload, {
            "file-list": $setup.attFileList,
            "onUpdate:fileList": _cache[4] || (_cache[4] = ($event: any) => (($setup.attFileList) = $event)),
            drag: "",
            multiple: "",
            action: $setup.uploadFileUrl,
            style: {"--el-color-primary":"#f36633"},
            data: $setup.AttForm,
            "before-remove": $setup.beforeRemove,
            headers: { Authorization: `Bearer ` + $setup.getUserInfo()!.tokenResult!.access_token }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_24, _toDisplayString($setup.isMobile ? _ctx.$t("mobileUploadMsg") : _ctx.$t("drag")), 1)
            ]),
            _: 1
          }, 8, ["file-list", "action", "data", "before-remove", "headers"])
        ])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createVNode(_component_el_alert, {
          title: _ctx.$t('submit_warning'),
          type: "warning",
          closable: false,
          "show-icon": ""
        }, null, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_component_el_button, {
          class: "submit-btn",
          onClick: $setup.submitIdea,
          disabled: $setup.form_title == '' || $setup.isEmptyHtml($setup.form_description) || $setup.categoryId == ''
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("submitIdea")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_el_button, {
          class: "cancel-btn",
          color: "white\n      ",
          onClick: $setup.resetSubmit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("reset")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _cache[9] || (_cache[9] = _createElementVNode("div", { style: {"height":"20vh"} }, null, -1)),
    _createElementVNode("section", _hoisted_27, _toDisplayString(_ctx.$t("aboutGSKMsg")), 1),
    _createElementVNode("section", _hoisted_28, [
      _createVNode(_component_FooterBar, { activeItem: 2 })
    ])
  ]))
}