import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "avatar-container" }
const _hoisted_2 = { class: "content-container" }
const _hoisted_3 = { class: "basicinfo-container" }
const _hoisted_4 = { class: "username" }
const _hoisted_5 = { class: "publish-time" }
const _hoisted_6 = { class: "detail-container" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "content" }
const _hoisted_9 = { class: "title-row" }
const _hoisted_10 = { class: "title-text" }
const _hoisted_11 = { class: "idea-text" }
const _hoisted_12 = { class: "idea-title" }
const _hoisted_13 = { class: "news-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_PostActions = _resolveComponent("PostActions")!

  return (_openBlock(), _createElementBlock("div", {
    class: "newscard-container",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCardClick && _ctx.handleCardClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_avatar, {
        src: 'data:image/jpeg;base64,' + _ctx.ideaItem.User.Avatar,
        class: "custom-avatar"
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.ideaItem.User.UserName), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatTimeAgo(_ctx.ideaItem.Idea.CreatedOn)), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.ideaItem.Idea.BannerImgUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.ideaItem.Idea.BannerImgUrl + '?' + _ctx.getSasTokenFromCache(),
              class: "news-pic"
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("idea")), 1),
              _createElementVNode("span", _hoisted_12, [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "grey-text" }, "\" ", -1)),
                _createTextVNode(_toDisplayString(_ctx.ideaItem.Idea.Title), 1),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "grey-text" }, " \"", -1))
              ])
            ]),
            _createVNode(_component_PostActions, {
              card: _ctx.ideaItem,
              customGap: '1rem'
            }, null, 8, ["card"])
          ]),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.removeHtmlTags(_ctx.ideaItem.Idea.Description)), 1)
        ])
      ])
    ])
  ]))
}