<template>
  <div id="app">
    <!-- Header导航栏部分 -->
    <header class="header">
      <HeaderBar :index="'/playground'" />
    </header>
    <div class="full-screen">
      <iframe
        ref="iframe"
        :src="iframeSrc"
        style="height: 100vh; width: 100vw"
        frameborder="0"
        marginwidth="0"
        marginheight="0"
        scrolling="no"
        allowtransparency="true"
      ></iframe>
    </div>
  </div>
</template>

<style scoped>
html,
body {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}
.full-screen {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
</style>
<script lang="ts">
import HeaderBar from "@/components/HeaderBar.vue";
import { getUserInfo } from "@/state/UserInfo";
import { ref, onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "PlaygroundView",
  components: {
    HeaderBar,
  },
  setup() {
    const iframe = ref<HTMLIFrameElement | null>(null);
    const showIframe = ref(true);
    const { locale } = useI18n();
    const iframeSrc = computed(
      () =>
        "/ai_endpoint/playground?language=" +
        // "https://hub-dev.gsk-rd-apps.com/ai_endpoint/playground?language=" +
        locale.value +
        "&Token=" +
        getUserInfo()?.tokenResult!.access_token +
        "&Platform=playground"
    );
    console.log(iframeSrc.value);
    onMounted(() => {
      //iframe.value = document.querySelector("iframe");
    });
    return { HeaderBar, iframe, iframeSrc, showIframe };
  },
};
</script>
