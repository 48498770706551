import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/star.svg'
import _imports_1 from '@/assets/icons/star-fill.svg'
import _imports_2 from '@/assets/icons/zan.svg'
import _imports_3 from '@/assets/icons/zan-fill.svg'
import _imports_4 from '@/assets/icons/comment.svg'


const _hoisted_1 = {
  src: _imports_0,
  class: "icon"
}
const _hoisted_2 = {
  src: _imports_1,
  class: "icon"
}
const _hoisted_3 = {
  class: "icon-text",
  style: {"margin-left":"4px"}
}
const _hoisted_4 = {
  src: _imports_2,
  class: "icon"
}
const _hoisted_5 = {
  src: _imports_3,
  class: "icon"
}
const _hoisted_6 = {
  class: "icon-text",
  style: {"margin-left":"4px"}
}
const _hoisted_7 = {
  class: "icon-button",
  id: "comment"
}
const _hoisted_8 = {
  class: "icon-text",
  style: {"margin-left":"4px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "postaction-bar",
    style: _normalizeStyle({ gap: _ctx.customGap })
  }, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleCollectClick(_ctx.localCard.Idea.IdeaId)), ["stop"])),
      class: "icon-button",
      id: "star"
    }, [
      _withDirectives(_createElementVNode("img", _hoisted_1, null, 512), [
        [_vShow, !_ctx.localCard.IsCollect]
      ]),
      _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
        [_vShow, _ctx.localCard.IsCollect]
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.localCard.Idea.CollectCount), 1)
    ]),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handleLikeClick(_ctx.localCard.Idea.IdeaId)), ["stop"])),
      class: "icon-button",
      id: "zan"
    }, [
      _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
        [_vShow, !_ctx.localCard.IsLike]
      ]),
      _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
        [_vShow, _ctx.localCard.IsLike]
      ]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.localCard.Idea.LikeCount), 1)
    ]),
    _createElementVNode("button", _hoisted_7, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_4,
        class: "icon"
      }, null, -1)),
      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.localCard.Idea.CommentCount), 1)
    ])
  ], 4))
}