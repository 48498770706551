import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 1000,
});

export function getSasTokenFromCache() {
  const sasToken = sessionStorage.getItem("sasToken");
  return sasToken;
}

export function saveSasToken(sasToken: string) {
  if (sasToken) {
    sessionStorage.setItem("sasToken", sasToken);
  } else {
    sessionStorage.removeItem("sasToken");
  }
}

export function getSasToken() {
  return axiosInstance
    .request({
      url: "api/FileHandler/GetSasToken",
      method: "get",
    })
    .then((res) => {
      saveSasToken(res.data.Data);
    })
    .catch((error) => {
      console.error(error);
    });
}
