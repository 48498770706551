import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "tool-bar" }
const _hoisted_3 = { class: "title-text" }
const _hoisted_4 = { class: "tool-bar-right" }
const _hoisted_5 = { class: "switch-tool" }
const _hoisted_6 = { class: "admin-text" }
const _hoisted_7 = {
  class: "admin-text",
  style: {"margin-right":"0"}
}
const _hoisted_8 = { class: "table-container" }
const _hoisted_9 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_10 = { style: {"display":"flex","align-items":"center","justify-content":"space-between"} }
const _hoisted_11 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "9",
  height: "9",
  viewBox: "0 0 8 9",
  style: {"margin-left":"2px"}
}
const _hoisted_12 = ["fill"]
const _hoisted_13 = { style: {"color":"#868693"} }
const _hoisted_14 = { style: {"color":"#868693"} }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementHeaderBar = _resolveComponent("ManagementHeaderBar")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_ManagementHeaderBar, { index: '/userpool' })
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("userPool")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("onlyAdmin")), 1),
          _createVNode(_component_el_switch, {
            modelValue: $setup.isAdmin,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isAdmin) = $event)),
            class: "admin-switch",
            size: "large",
            onChange: $setup.handleAdminFilterChange
          }, null, 8, ["modelValue", "onChange"])
        ]),
        _createVNode(_component_el_input, {
          modelValue: $setup.searchText,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.searchText) = $event)),
          size: "large",
          placeholder: _ctx.$t('searchAnything'),
          "prefix-icon": $setup.Search,
          style: {"--el-color-primary":"#f36633","width":"15rem"},
          onInput: $setup.handleSearchTextInput,
          clearable: "true"
        }, null, 8, ["modelValue", "placeholder", "prefix-icon", "onInput"]),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("ideas") + "："), 1),
        _createVNode(_component_el_input_number, {
          size: "large",
          "controls-position": "right",
          style: {"--el-color-primary":"#f36633","width":"7rem"},
          min: "0",
          max: $setup.IdeaCountNum1 ? $setup.IdeaCountNum1 : 999999,
          modelValue: $setup.IdeaCountNum,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.IdeaCountNum) = $event)),
          onChange: $setup.handleIdeaNumChange
        }, null, 8, ["max", "modelValue", "onChange"]),
        _cache[5] || (_cache[5] = _createTextVNode(" ~ ")),
        _createVNode(_component_el_input_number, {
          size: "large",
          "controls-position": "right",
          style: {"--el-color-primary":"#f36633","width":"7rem"},
          modelValue: $setup.IdeaCountNum1,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.IdeaCountNum1) = $event)),
          min: $setup.IdeaCountNum,
          onChange: $setup.handleIdeaNumChange
        }, null, 8, ["modelValue", "min", "onChange"]),
        _createVNode(_component_el_button, {
          class: "export-btn",
          onClick: $setup.exportExcel
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("exportExcel")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      _createVNode(_component_el_table, {
        data: $setup.tableData,
        style: {"width":"100%","--el-color-primary":"#f36633"},
        onSortChange: $setup.sortChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "UserName",
            label: _ctx.$t('userName'),
            fixed: "",
            "min-width": "140"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_el_avatar, {
                  src: 'data:image/jpeg;base64,' + $setup.findDataById(scope.row.UserId)?.Avatar!,
                  size: 24,
                  class: "custom-avatar"
                }, null, 8, ["src"]),
                _createTextVNode(_toDisplayString($setup.findDataById(scope.row.UserId)!["UserName"]), 1)
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "Email",
            label: _ctx.$t('email'),
            "min-width": "200"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "IdeaCount",
            label: _ctx.$t('ideas'),
            sortable: "custom",
            width: "100"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_popover, {
                placement: "bottom",
                width: 200,
                trigger: "hover",
                onBeforeEnter: ($event: any) => ($setup.handlePopover(scope.row.UserId))
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_el_badge, {
                    value: $setup.findDataById(scope.row.UserId)!.IdeaCount
                  }, null, 8, ["value"])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_table, {
                    style: {"width":"100%"},
                    data: $setup.statusData
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        width: "160",
                        props: "statusInfo"
                      }, {
                        default: _withCtx((scope) => [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", {
                              style: _normalizeStyle(`color: ${$setup.getStatusColor(
                          $setup.statusData[scope.$index].StatusCode
                        )}`)
                            }, _toDisplayString($setup.getStatusText($setup.statusData[scope.$index].StatusCode)), 5),
                            _createElementVNode("div", null, [
                              _createTextVNode(_toDisplayString($setup.statusData[scope.$index].StatusCount) + " ", 1),
                              (_openBlock(), _createElementBlock("svg", _hoisted_11, [
                                _createElementVNode("circle", {
                                  cx: "4",
                                  cy: "4.5",
                                  r: "4",
                                  fill: 
                              $setup.getStatusColor(
                                $setup.statusData[scope.$index].StatusCode
                              )
                            
                                }, null, 8, _hoisted_12)
                              ]))
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["data"])
                ]),
                _: 2
              }, 1032, ["onBeforeEnter"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "CreateTime",
            label: _ctx.$t('createTime')
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", _hoisted_13, _toDisplayString($setup.formatTimestamp($setup.findDataById(scope.row.UserId)!.CreatedOn)), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "LastUpdate",
            label: _ctx.$t('lastUpdate')
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", _hoisted_14, _toDisplayString($setup.formatTimestamp($setup.findDataById(scope.row.UserId)!.ModifiedOn)), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "ManageAuthority",
            label: _ctx.$t('manageAuthority')
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_switch, {
                value: $setup.findDataById(scope.row.UserId)!.Authority == 1,
                class: "manage-authority-switch",
                size: "large",
                disabled: $setup.findDataById(scope.row.UserId)!.UserId==$setup.getUserInfo()!.graphUserInfo!.UserId,
                onChange: ($event: any) => ($setup.handleAdminChange(scope.row.UserId, $event))
              }, null, 8, ["value", "disabled", "onChange"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('action'),
            width: "200"
          }, {
            default: _withCtx((scope) => [
              (!scope.row.PendingAdmit && $setup.findDataById(scope.row.UserId)!.UserId!=$setup.getUserInfo()!.graphUserInfo!.UserId)
                ? (_openBlock(), _createBlock(_component_el_icon, {
                    key: 0,
                    onClick: () => $setup.handleRowDelete(scope.row.UserId),
                    style: {"cursor":"pointer"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Delete)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true),
              (scope.row.PendingAdmit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(_component_el_button, {
                      type: "success",
                      round: "",
                      onClick: ($event: any) => ($setup.handleAdmit(scope.row.UserId, 1))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("approve")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      round: "",
                      onClick: ($event: any) => ($setup.handleAdmit(scope.row.UserId, 0))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("reject")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["data", "onSortChange"])
    ]),
    _createElementVNode("footer", _hoisted_16, [
      _createVNode(_component_el_pagination, {
        small: "",
        background: "",
        layout: "prev, pager, next",
        "page-count": $setup.totalPageCount,
        onCurrentChange: $setup.handleCurrentPageChange,
        style: {"--el-color-primary":"#f36633"}
      }, null, 8, ["page-count", "onCurrentChange"]),
      _createVNode(_component_el_select, {
        class: "select-number",
        modelValue: $setup.pageNum,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.pageNum) = $event)),
        onChange: $setup.handlePageNumChange
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([10, 20, 30, 40, 50], (item) => {
            return _createVNode(_component_el_option, {
              key: item,
              label: item + '/page',
              value: item
            }, null, 8, ["label", "value"])
          }), 64))
        ]),
        _: 1
      }, 8, ["modelValue", "onChange"])
    ])
  ], 64))
}