<template>
  <!-- Header导航栏部分 -->
  <header class="header">
    <ManagementHeaderBar :index="'/userpool'" />
  </header>

  <!-- 功能栏 -->
  <section class="tool-bar">
    <span class="title-text">{{ $t("userPool") }}</span>
    <div class="tool-bar-right">
      <div class="switch-tool">
        <span class="admin-text">{{ $t("onlyAdmin") }}</span>
        <el-switch
          v-model="isAdmin"
          class="admin-switch"
          size="large"
          @change="handleAdminFilterChange"
        />
      </div>
      <el-input
        v-model="searchText"
        size="large"
        :placeholder="$t('searchAnything')"
        :prefix-icon="Search"
        style="--el-color-primary: #f36633; width: 15rem"
        @input="handleSearchTextInput"
        clearable="true"
      >
      </el-input>
      <div class="admin-text" style="margin-right: 0">
        {{ $t("ideas") + "：" }}
      </div>
      <el-input-number
        size="large"
        controls-position="right"
        style="--el-color-primary: #f36633; width: 7rem"
        min="0"
        :max="IdeaCountNum1 ? IdeaCountNum1 : 999999"
        v-model="IdeaCountNum"
        @change="handleIdeaNumChange"
      />
      ~
      <el-input-number
        size="large"
        controls-position="right"
        style="--el-color-primary: #f36633; width: 7rem"
        v-model="IdeaCountNum1"
        :min="IdeaCountNum"
        @change="handleIdeaNumChange"
      />
      <el-button class="export-btn" @click="exportExcel">{{
        $t("exportExcel")
      }}</el-button>
    </div>
  </section>

  <!-- 表格 -->
  <section class="table-container">
    <el-table
      :data="tableData"
      style="width: 100%; --el-color-primary: #f36633"
      @sort-change="sortChange"
    >
      <el-table-column prop="UserName" :label="$t('userName')" fixed min-width="140">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-avatar
              :src="'data:image/jpeg;base64,' + findDataById(scope.row.UserId)?.Avatar!"
              :size="24"
              class="custom-avatar"
            ></el-avatar
            >{{ findDataById(scope.row.UserId)!["UserName"] }}
          </div>
        </template></el-table-column
      >
      <el-table-column prop="Email" :label="$t('email')"  min-width="200" />
      <el-table-column
        prop="IdeaCount"
        :label="$t('ideas')"
        sortable="custom"
        width="100"
      >
        <template #default="scope">
          <el-popover
            placement="bottom"
            :width="200"
            trigger="hover"
            @before-enter="handlePopover(scope.row.UserId)"
          >
            <template #reference>
              <el-badge
                :value="findDataById(scope.row.UserId)!.IdeaCount"
              ></el-badge>
            </template>
            <template #default>
              <el-table style="width: 100%" :data="statusData">
                <el-table-column width="160" props="statusInfo">
                  <template #default="scope">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div
                        :style="`color: ${getStatusColor(
                          statusData[scope.$index].StatusCode
                        )}`"
                      >
                        {{ getStatusText(statusData[scope.$index].StatusCode) }}
                      </div>
                      <div>
                        {{ statusData[scope.$index].StatusCount }}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          viewBox="0 0 8 9"
                          style="margin-left: 2px"
                        >
                          <circle
                            cx="4"
                            cy="4.5"
                            r="4"
                            :fill="
                              getStatusColor(
                                statusData[scope.$index].StatusCode
                              )
                            "
                          />
                        </svg>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="CreateTime" :label="$t('createTime')">
        <template #default="scope">
          <span style="color: #868693">{{
            formatTimestamp(findDataById(scope.row.UserId)!.CreatedOn)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="LastUpdate" :label="$t('lastUpdate')">
        <template #default="scope">
          <span style="color: #868693">{{
            formatTimestamp(findDataById(scope.row.UserId)!.ModifiedOn)
          }}</span></template
        >
      </el-table-column>
      <el-table-column prop="ManageAuthority" :label="$t('manageAuthority')">
        <template #default="scope">
          <el-switch
            :value="findDataById(scope.row.UserId)!.Authority == 1"
            class="manage-authority-switch"
            size="large"
            :disabled="findDataById(scope.row.UserId)!.UserId==getUserInfo()!.graphUserInfo!.UserId"
            @change="handleAdminChange(scope.row.UserId, $event)" /></template
      ></el-table-column>
      <el-table-column :label="$t('action')" width="200">
        <template #default="scope">
          <el-icon
            v-if="!scope.row.PendingAdmit && findDataById(scope.row.UserId)!.UserId!=getUserInfo()!.graphUserInfo!.UserId"
            @click="() => handleRowDelete(scope.row.UserId)"
            style="cursor: pointer"
            ><Delete
          /></el-icon>
          <div v-if="scope.row.PendingAdmit">
            <el-button
              type="success"
              round
              @click="handleAdmit(scope.row.UserId, 1)"
              >{{ $t("approve") }}</el-button
            >
            <el-button
              type="danger"
              round
              @click="handleAdmit(scope.row.UserId, 0)"
              >{{ $t("reject") }}</el-button
            >
          </div></template
        ></el-table-column
      >
    </el-table>
  </section>
  <!-- Footer 页码部分-->
  <footer class="footer">
    <el-pagination
      small
      background
      layout="prev, pager, next"
      :page-count="totalPageCount"
      @current-change="handleCurrentPageChange"
      style="--el-color-primary: #f36633"
    />

    <!-- Footer 选择个数部分 -->
    <el-select
      class="select-number"
      v-model="pageNum"
      @change="handlePageNumChange"
    >
      <el-option
        v-for="item in [10, 20, 30, 40, 50]"
        :key="item"
        :label="item + '/page'"
        :value="item"
      ></el-option>
    </el-select>
  </footer>
</template>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.tool-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.title-text {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.tool-bar-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.admin-switch {
  --el-switch-on-color: #f36633;
}

.admin-text {
  color: #3c3c43;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  flex-shrink: 0;
  margin-right: 1rem;
}

.switch-tool {
  display: flex;
  align-items: center;
  margin-right: 5rem;
}

.table-container {
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 10rem;
}
.manage-authority-switch {
  margin-left: 25px;
  --el-switch-on-color: #f36633;
}
:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #f36633;
  border-color: #f36633;
}

.custom-avatar {
  margin-right: 0.5rem;
  border: 2px solid rgba(174, 174, 183, 0.2);
  flex-shrink: 0;
}

.el-badge {
  --el-badge-bg-color: #f36633 !important;
  --el-color-danger: #f36633 !important;
}

.custom-pagination {
  position: fixed;
  left: 2rem;
  bottom: 2rem;
}
.footer {
  width: 100%;
  position: fixed;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
}
.select-number {
  width: 10rem;
  --el-color-primary: #f36633;
}

.export-btn {
  color: #fff;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  --el-button-bg-color: #f36633 !important;
  --el-button-border-color: #f36633 !important;
  --el-button-hover-bg-color: #376f7b !important;
  --el-button-hover-text-color: white !important;
  --el-button-hover-border-color: #376f7b !important;
  --el-button-active-bg-color: #376f7b !important;
  --el-button-active-border-color: #376f7b !important;
}
</style>

<script lang="ts">
import ManagementHeaderBar from "@/components/ManagementHeaderBar.vue";
import { ref, onMounted, inject } from "vue";
import { Search, Delete } from "@element-plus/icons-vue";
import { AxiosInstance } from "axios";
import { getUserInfo } from "@/state/UserInfo";
import { formatTimestamp } from "@/utils/formatTimestamp";
import { getStatusText } from "@/utils/getStatusText";
import { getStatusColor } from "@/utils/getStatusColor";
import XLSX from "xlsx";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";

export default {
  name: "UserPoolView",
  components: {
    ManagementHeaderBar,
  },
  setup() {
    interface UserInfo {
      UserId: number;
      UserName: string;
      Password: string | null;
      Email: string;
      MudId: string;
      EnName: string | null;
      CnName: string | null;
      Avatar: ArrayBuffer;
      CreatedOn: string;
      CreatedBy: number | null;
      ModifiedOn: string;
      ModifiedBy: number | null;
      Authority: number;
      IdeaCount: number;
      PageCount: number;
    }
    interface StatusInfo {
      StatusCode: number;
      StatusCount: number;
    }
    const isAdmin = ref(false);
    const searchText = ref("");
    const IdeasCountRange = ref();
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    const IdeasCountRangeOptions = ref([
      { value: "0-4", label: "0 ~ 4" },
      { value: "5-7", label: "5 ~ 7" },
      { value: "8-12", label: "8 ~ 12" },
    ]);
    const IdeaCountNum = ref(Number);
    const IdeaCountNum1 = ref(Number);
    const tableData = ref<UserInfo[]>([]);
    const statusData = ref<StatusInfo[]>([]);
    const pageNum = ref(10);
    const currentPage = ref(1);
    const totalPageCount = ref(5);
    const orderColumn = ref("");
    const order = ref("");
    const { t } = useI18n();

    onMounted(async () => {
      await getUserList();
    });
    /* eslint-disable */
    const getUserList = async () => {
      return axiosInstance
        .request({
          url: "/api/user/GetUserList",
          method: "get",
          params: {
            PageSize: pageNum.value,
            PageIndex: currentPage.value,
            isAdmin: isAdmin.value,
            IdeaCountNum: IdeaCountNum.value,
            IdeaCountNum1: IdeaCountNum1.value,
            SearchText: searchText.value,
            OrderColumn: orderColumn.value,
            Order: order.value,
          },
        })
        .then((res) => {
          console.log(res);
          tableData.value = res.data.Data;
          if (
            tableData.value[0] &&
            "PageCount" in tableData.value[0] &&
            tableData.value[0].PageCount
          ) {
            totalPageCount.value = tableData.value[0].PageCount;
          } else {
            totalPageCount.value = 1;
          }
        });
    };

    const handleRowDelete = async (UserId: number) => {
      console.log("handleRowDelete", UserId);
      await deleteUser(UserId);
      getUserList();
    };

    const deleteUser = (UserId: number) => {
      ElMessageBox.confirm(`${t("warningmsg")}`, `${t("warning")}`, {
        confirmButtonText: `${t("confirm")}`,
        cancelButtonText: `${t("cancel")}`,
        type: "warning",
      })
        .then(() => {
          return axiosInstance
            .request({
              url: "/api/user/DeleteUser",
              method: "post",
              data: {
                DeleteUserId: UserId,
                UserId: getUserInfo()?.graphUserInfo!.UserId,
              },
            })
            .then((res) => {
              console.log("deleteUser", res.data);
              ElMessage({
                type: "success",
                message: `${t("deletesuccess")}`,
                offset: 100,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: `${t("deletecancel")}`,
            offset: 100,
          });
        });
    };

    const handleAdminFilterChange = async (value: string) => {
      getUserList();
    };

    const handleAdminChange = async (UserId: number, value: boolean) => {
      const Authority = value ? 1 : 0;
      await UpdateUserAuthority(Authority, UserId);
      console.log(value);
      getUserList();
    };

    const UpdateUserAuthority = (Authority: number, UserId: number) => {
      return axiosInstance
        .request({
          url: "/api/user/UpdateUserAuthority",
          method: "post",
          data: {
            Userid: UserId,
            Authority: Authority,
            ModifiedBy: getUserInfo()?.graphUserInfo!.UserId,
          },
        })
        .then((res) => {
          console.log("UpdateUserAuthority", res.data);
        });
    };
    const handleSearchTextInput = (value: string) => {
      searchText.value = value;
      getUserList();
    };

    const findDataById = (UserId: number) => {
      return tableData.value.find((data) => data.UserId === UserId);
    };

    const handleCurrentPageChange = (val: number) => {
      currentPage.value = val;
      getUserList();
    };

    const handlePageNumChange = () => {
      getUserList();
    };

    const handleAdmit = async (UserId: number, Authority: number) => {
      await RespondToAdminRequest(UserId, Authority);
      getUserList();
    };

    const RespondToAdminRequest = (UserId: number, Authority: number) => {
      return axiosInstance
        .request({
          url: "/api/user/RespondToAdminRequest",
          method: "post",
          data: {
            UserId: UserId,
            Authority: Authority,
            ModifiedBy: getUserInfo()?.graphUserInfo!.UserId,
          },
        })
        .then((res) => {
          console.log("RespondToAdminRequest", res.data);
        });
    };

    const handlePopover = (UserId: number) => {
      console.log(UserId);
      return axiosInstance
        .request({
          url: "/api/idea/GetMyIdeaStatusCount",
          method: "get",
          params: {
            UserId: UserId,
          },
        })
        .then((res) => {
          statusData.value = res.data.Data.filter(
            (item: { StatusCount: number; StatusCode: number }) =>
              item.StatusCount !== 0
          );
          console.log(res);
          console.log(statusData.value);
        });
    };
    const sortChange = (e: any) => {
      if (e.order) {
        orderColumn.value = e.prop;
        order.value = e.order.toLowerCase() === "ascending" ? "ASC" : "DESC";
      } else {
        orderColumn.value = "";
        order.value = "";
      }
      getUserList();
    };

    const handleIdeaNumChange = () => {
      console.log("IdeaCountNum:" + IdeaCountNum.value);
      console.log("IdeaCountNum1:" + IdeaCountNum1.value);
      getUserList();
    };
    const exportExcel = async () => {
      let wb = XLSX.utils.book_new();
      let sheetData = ExportUserList();
      let sheet = XLSX.utils.json_to_sheet(await sheetData);
      XLSX.utils.book_append_sheet(wb, sheet, "Sheet1");
      XLSX.writeFile(wb, "DigitalInnovationHubUserData.xlsx");
    };
    const ExportUserList = () => {
      return axiosInstance
        .request({
          url: "/api/User/ExportUserList",
          method: "get",
        })
        .then((res) => {
          return JSON.parse(res.data.Data);
        });
    };
    return {
      isAdmin,
      searchText,
      IdeasCountRange,
      IdeasCountRangeOptions,
      tableData,
      pageNum,
      statusData,
      currentPage,
      totalPageCount,
      IdeaCountNum,
      IdeaCountNum1,
      sortChange,
      handlePopover,
      formatTimestamp,
      findDataById,
      handleAdminFilterChange,
      handleSearchTextInput,
      getStatusText,
      getStatusColor,
      handleCurrentPageChange,
      handlePageNumChange,
      handleRowDelete,
      handleAdminChange,
      handleAdmit,
      handleIdeaNumChange,
      exportExcel,
      getUserInfo,
      Search,
      Delete,
    };
  },
};
</script>
