import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { style: {"padding":"2rem","font-size":"2rem","margin-top":"4rem"} }
const _hoisted_3 = {
  ref: "chartDom",
  class: "chartDom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementHeaderBar = _resolveComponent("ManagementHeaderBar")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_ManagementHeaderBar, { index: '/stastics' })
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("ideaStatistics")), 1),
    _createVNode(_component_el_select, {
      modelValue: $setup.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
      class: "m-2",
      size: "large",
      placeholder: _ctx.$t('allTime'),
      style: {"width":"240px","--el-color-primary":"#f36633","padding-left":"2rem"},
      onChange: $setup.selectChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value,
            style: {"--el-color-primary":"#f36633"}
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "placeholder", "onChange"]),
    _createElementVNode("div", _hoisted_3, null, 512)
  ], 64))
}