<template>
  <div id="app">
    <!-- Header导航栏部分 -->
    <header class="header">
      <HeaderBar :index="'1'" />
    </header>
    <!-- Banner部分 -->
    <section class="banner-container">
      <div class="rectangle"></div>
      <div class="banner-text-container">
        <div class="banner-title">{{ $t("viewProfile") }}</div>
      </div>
    </section>
    <!-- 用户信息部分 -->
    <section class="user-info-container">
      <el-avatar :src="'data:image/jpeg;base64,' + userInfo?.graphUserInfo!.avatar" :size="120"></el-avatar>
      <div class="user-name">
        {{
          getUserInfo()?.graphUserInfo!.displayName
            ? getUserInfo()?.graphUserInfo!.displayName
            : getUserInfo()!.graphUserInfo!.userName
        }}
      </div>
      <div class="mail-address">{{ userInfo?.graphUserInfo!.mail }}</div>

      <el-row :gutter="20" class="detail-info">
        <el-col :span="12" v-for="(value, key) in userinfo" :key="key" class="info-value" @click="handleColClick(key)">
          <div class="mail-address">{{ key }}</div>
          <div class="info-value">{{ value }}</div>
        </el-col>
      </el-row>
    </section>

    <section class="grey-container" v-if="!isIdeas">
      <div class="my-ideas" style="text-align: left">
        {{ $t("myComments") }}
      </div>
      <div v-for="(comment, index) in commentList" :key="index">
        <div class="comment-bar">
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 9"
            :fill="getStatusColor(comment.Comment.Status)" style="margin-right: 0.5rem">
            <circle cx="4" cy="4.5" r="4" :fill="getStatusColor(comment.Comment.Status)" />
          </svg>
          <span class="idea-text">{{ $t("idea") }}</span>
          <span class="idea-title"><span class="grey-text">" </span>{{ comment.Comment.Title }}
            <span class="grey-text"> "</span></span>
        </div>
        <div class="comment-content">
          <div class="comment-detail">
            {{ comment.Comment.Content }}
          </div>
          <div class="comment-content-lastbar" style="margin-top: 0.5rem">
            <div style="display: flex; align-items: center">
              <div class="statusAndTime">
                {{ formatTimeAgo(comment.Comment.CreatedOn) }}
              </div>
              <button class="icon-button" @click="
                comment.IsLike
                  ? CommentLikeCancel(comment.Comment.CommId)
                  : CommentLike(comment.Comment.CommId)
                ">
                <img src="@/assets/icons/zan.svg" class="icon" v-show="!comment.IsLike" />
                <img src="@/assets/icons/zan-fill.svg" class="icon" v-show="comment.IsLike" />
                <span class="icon-text" style="margin-left: 4px">{{
                  comment.Comment.LikeCount
                }}</span></button><!-- 点赞图标 -->
            </div>

            <el-popover placement="bottom-end" trigger="hover" popper-class="custom-popper">
              <template #reference>
                <el-icon style="color: #484851; cursor: pointer">
                  <MoreFilled />
                </el-icon>
              </template>
              <template #default>
                <div style="
                    width: 100%;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                  ">
                  <button class="custom-btn" @click="DeleteComment(comment.Comment.CommId)">
                    {{ $t("delete") }}
                  </button>
                </div>
              </template>
            </el-popover>
          </div>
        </div>
      </div>
    </section>

    <!-- 没有idea时 -->
    <section class="grey-container" v-if="ideaList.length == 0 && isIdeas">
      <div style="margin-top: 2rem">{{ $t("noSubmissions") }}</div>
      <el-button class="submit-btn" @click="navToSubmit" style="margin-top: 1rem">{{ $t("submitIdea") }}</el-button>
    </section>
    <!-- 有idea时 -->
    <section class="grey-container" v-if="ideaList.length != 0 && isIdeas">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <div class="my-ideas">{{ $t("myIdeas") }}</div>
        <el-button class="submit-btn" @click="navToSubmit">{{
          $t("submitIdea")
        }}</el-button>
      </div>
      <div style="
          display: flex;
          margin-top: 1rem;
          width: 100%;
          align-items: start;
          cursor: pointer;
        " v-for="(idea, index) in ideaList" :key="index" @click="navToDetail(idea.Idea.IdeaId)">
        <img :src="idea.Idea.BannerImgUrl + '?' + getSasTokenFromCache()" class="custom-img"
          v-if="idea.Idea.BannerImgUrl" />
        <div style="
            width: 100%;
            padding-left: 1rem;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid rgba(211, 211, 211, 0.5);
          ">
          <div class="title-bar">
            <div>
              <span class="idea-text">{{ $t("idea") }}</span>
              <span class="idea-title"><span class="grey-text">" </span>{{ idea.Idea.Title }}
                <span class="grey-text">"</span></span>
            </div>
            <div style="display: flex; align-items: center; gap: 1rem">
              <PostActions :card="idea" />
              <div @click.stop>
                <el-popover placement="bottom-end" trigger="hover" popper-class="custom-popper">
                  <template #reference>
                    <el-icon style="color: #484851; cursor: pointer">
                      <MoreFilled />
                    </el-icon>
                  </template>
                  <template #default>
                    <div style="
                        width: 100%;
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                      ">
                      <button class="custom-btn" @click="handleDeleteIdeaClick(idea.Idea.IdeaId)">
                        {{ $t("delete") }}
                      </button>
                    </div>
                  </template>
                </el-popover>
              </div>
            </div>
          </div>
          <div style="
              text-align: left;
              display: flex;
              align-items: center;
              margin-bottom: 0.2rem;
            " class="statusAndTime">
            <div style="display: flex; align-items: center" :style="`color: ${getStatusColor(idea.Idea.Status)}`">
              {{ getStatusText(idea.Idea.Status) }}
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 8 9" fill="none"
                style="margin-left: 0.5rem">
                <circle cx="4" cy="4.5" r="4" :fill="getStatusColor(idea.Idea.Status)" />
              </svg>
            </div>
            <div style="margin-left: 0.5rem">
              {{ formatTimeAgo(idea.Idea.CreatedOn) }}
            </div>
          </div>
          <div style="font-size: 0.875rem; text-align: left" class="idea-description">
            {{ removeHtmlTags(idea.Idea.Description) }}
          </div>
        </div>
      </div>
    </section>
    <!-- footer部分 -->
    <section>
      <img src="@/assets/images/index/Rectangle_bottom.png" style="width: 100%" />
      <div class="footer-text">{{ $t("aboutGSKMsg") }}</div>
    </section>
  </div>
</template>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.banner-container {
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: url("@/assets/images/browse/TOP.png");
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
}

.rectangle {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 76px;
  background-image: url("@/assets/images/index/Rectangle.png");
  background-position: center;
  background-size: cover;
  align-items: end;
}

.banner-text-container {
  top: 150px;
  position: relative;
  z-index: 999;
}

.banner-title {
  color: #3c3c43;
  text-align: center;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.user-info-container {
  display: flex;
  padding-top: 2rem;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
}

.el-avatar {
  border: 6px solid rgba(174, 174, 183, 0.2);
}

.user-name {
  margin-top: 1.5rem;
  color: #484851;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.mail-address {
  margin-top: 0.5rem;
  color: #868693;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.detail-info {
  margin-top: 2rem;
  width: 60vw;
}

.info-value {
  margin-top: 0.5rem;
  color: #484851;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.detail-info .el-col {
  position: relative;
}

.detail-info .el-col::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 40%;
  width: 1px;
  background-color: #c9c9cf;
}

.detail-info .el-col:first-child::after {
  display: none;
  /* 去掉最后一个元素的分割线 */
}

.detail-info .el-col:first-child {
  border-right: none;
}

.grey-container {
  padding-top: 2.5rem;
  min-height: 30vh;
  background-color: #f6f6f6;
  text-align: center;
  color: #aeaeb7;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 20vw;
  padding-right: 20vw;
  padding-bottom: 2.5rem;
}

.submit-btn {
  color: white;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
  height: 2rem;
  padding: 0px 1rem;
  border-radius: 10px;
  --el-button-bg-color: #f36633 !important;
  --el-button-border-color: #f36633 !important;
  --el-button-hover-bg-color: #376f7b !important;
  --el-button-hover-text-color: white !important;
  --el-button-hover-border-color: #376f7b !important;
  --el-button-active-bg-color: #376f7b !important;
  --el-button-active-border-color: #376f7b !important;
}

.footer-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  color: #484851;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}

.my-ideas {
  color: #3c3c43;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  /* 150% */
}

.info-value {
  text-align: center;
}

.info-value:nth-child(2),
.info-value:nth-child(3) {
  cursor: pointer;
}

.custom-img {
  width: 8rem;
  height: 6rem;
  border-radius: 0.5rem;
}

.title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.3rem;
  text-align: left;
}

.idea-text {
  color: #868693;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-right: 8px;
}

.idea-title {
  color: #376f7b;
  /* 16px-bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.grey-text {
  color: #868693;
}

.statusAndTime {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  /* 150% */
}

.idea-description {
  text-align: left;
  color: #484851;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  margin-top: 0.4rem;
  /* 150% */
}

.custom-btn {
  width: 100%;
  color: #484851;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  /* 150% */
  border: none;
  background-color: transparent;
  font-family: GSK Precision;
  text-align: left;
  height: 2rem;
  cursor: pointer;
}

.custom-btn:hover {
  background: #f6f6f6;
}

.comment-bar {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.comment-content {
  background: #fff;
  width: 100%;
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 1rem 0.875rem;
}

.comment-detail {
  color: #484851;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3125rem;
  text-align: start;
}

.comment-content-lastbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.icon-button {
  display: flex;
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 1rem;
}

.icon {
  width: 16px;
  height: 16px;
}

.icon-text {
  color: #868693;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  /* 150% */
}

@media only screen and (max-width: 1200px) {
  .grey-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>

<style lang="scss">
.el-popover.el-popper.custom-popper {
  min-width: 80px !important;
  width: 80px !important;
  padding: 0 !important;
  border-radius: 0.5rem !important;
  border: 1px solid #c9c9cf !important;
  box-shadow: 0px 0px 8px 0px rgba(60, 60, 67, 0.2) !important;
}
</style>

<script lang="ts">
import HeaderBar from "@/components/HeaderBar.vue";
import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import PostActions from "@/components/PostActions.vue";
import { AxiosInstance } from "axios";
import { MoreFilled } from "@element-plus/icons-vue";
import { getUserInfo } from "@/state/UserInfo";
import { getStatusText } from "@/utils/getStatusText";
import { getStatusColor } from "@/utils/getStatusColor";
import { getSasTokenFromCache } from "@/state/getSasToken";
import { removeHtmlTags } from "@/utils/removeHtmlTags";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";

export default {
  name: "ProfileView",
  components: {
    HeaderBar,
    PostActions,
  },
  setup() {
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    const router = useRouter();
    interface IdeaItem {
      Idea: Idea;
      IsCollect: boolean;
      IsLike: boolean;
      User: User;
    }

    interface Idea {
      CollectCount: number;
      CommentCount: number;
      CreatedBy: number;
      CreatedOn: string;
      Description: string;
      IdeaId: number;
      LikeCount: number;
      ModifiedBy: number;
      ModifiedOn: string;
      Owner: number;
      PlaygroundCreatedBy: number;
      PlaygroundCreatedOn: string;
      PlaygroundModifiedBy: number;
      PlaygroundModifiedOn: string;
      State: number;
      Status: number;
      SubmittedOn: string;
      Title: string;
      BannerImgUrl: string;
    }

    interface User {
      Avatar: string;
      UserId: number;
      UserName: string;
    }

    interface Comment {
      CommId: number;
      Content: string;
      CreatedBy: number;
      CreatedOn: string;
      IdeaId: number;
      LikeCount: number;
      ModifiedBy: number;
      ModifiedOn: string;
      PareCommId: number;
      State: number;
      Title: string;
      Status: number;
    }

    interface CommentItem {
      Comment: Comment;
      IsLike: boolean;
      Reply: Reply[];
    }

    interface Reply {
      User: User;
      Comment: Comment;
    }

    const userInfo = getUserInfo();
    const ideaList = ref<IdeaItem[]>([]);
    const commentList = ref<CommentItem[]>([]);

    const isIdeas = ref(true);
    const userinfo = ref({
      Ideas: 6,
      Comments: 2,
    });
    const navToSubmit = () => {
      router.push("/submit");
    };
    const { t } = useI18n();
    onMounted(async () => {
      window.scrollTo(0, 0);
      await getMyIdeaList();
      await getMyCommentList();
    });

    const getMyIdeaList = async () => {
      return axiosInstance
        .request({
          url: "api/Idea/GetMyIdeaList",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
            PageSize: 100,
          },
          method: "get",
        })
        .then((res) => {
          ideaList.value = res.data.Data;
          userinfo.value.Ideas = ideaList.value.length;
          console.log(res.data.Data);
        });
    };

    const handleDeleteIdeaClick = async (IdeaId: number) => {
      await deleteIdea(IdeaId);
      await getMyIdeaList();
    };

    const deleteIdea = (IdeaId: number) => {
      return new Promise(() => {
        ElMessageBox.confirm(`${t("warningmsg")}`, `${t("warning")}`, {
          confirmButtonText: `${t("confirm")}`,
          cancelButtonText: `${t("cancel")}`,
          type: "warning",
        })
          .then(() => {
            axiosInstance
              .request({
                url: "api/Idea/DeleteIdea",
                data: {
                  UserId: getUserInfo()!.graphUserInfo!.UserId,
                  ideaId: IdeaId,
                },
                method: "post",
              })
              .then((res) => {
                console.log("Delete Successfully.", res);
                ElMessage({
                  type: "success",
                  message: `${t("deletesuccess")}`,
                  offset: 100,
                });
              });
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: `${t("deletecancel")}`,
              offset: 100,
            });
          });
      });
    };

    const getMyCommentList = async () => {
      return axiosInstance
        .request({
          url: "api/Comment/GetMyCommentList",
          params: {
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "get",
        })
        .then((res) => {
          console.log(res);
          commentList.value = res.data.Data;
          userinfo.value.Comments = commentList.value.length;
          console.log("getMyCommentList", commentList.value);
        });
    };

    const handleColClick = (key: string) => {
      if (key === "Ideas" || key === "Comments") {
        isIdeas.value = key === "Ideas";
      }
    };
    /* eslint-disable */
    const formatTimeAgo = inject("formatTimeAgo") as (
      timestamp: string
    ) => string;
    const navToDetail = (IdeaId: number) => {
      router.push({
        path: "/ideas/" + IdeaId,
      });
    };
    const CommentLike = (CommId: number) => {
      axiosInstance
        .request({
          url: "api/Comment/CommentLike",
          data: {
            CommId: CommId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
          getMyCommentList();
        });
    };
    const CommentLikeCancel = (CommId: number) => {
      axiosInstance
        .request({
          url: "api/Comment/CommentLikeCancel",
          data: {
            CommId: CommId,
            UserId: getUserInfo()!.graphUserInfo!.UserId,
          },
          method: "post",
        })
        .then((res) => {
          console.log(res);
          getMyCommentList();
        });
    };

    const DeleteComment = (CommId: number) => {
      ElMessageBox.confirm(`${t("warningmsg")}`, `${t("warning")}`, {
        confirmButtonText: `${t("confirm")}`,
        cancelButtonText: `${t("cancel")}`,
        type: "warning",
      })
        .then(() => {
          axiosInstance
            .request({
              url: "api/Comment/DeleteComment",
              data: {
                CommId: CommId,
                UserId: getUserInfo()!.graphUserInfo!.UserId,
              },
              method: "post",
            })
            .then((res) => {
              console.log(res);
              getMyCommentList();
              ElMessage({
                type: "success",
                message: `${t("deletesuccess")}`,
                offset: 100,
              });
            });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: `${t("deletecancel")}`,
            offset: 100,
          });
        });
    };
    return {
      userinfo,
      ideaList,
      isIdeas,
      commentList,
      userInfo,
      navToSubmit,
      getStatusText,
      getStatusColor,
      handleDeleteIdeaClick,
      handleColClick,
      formatTimeAgo,
      navToDetail,
      CommentLike,
      CommentLikeCancel,
      DeleteComment,
      getSasTokenFromCache,
      removeHtmlTags,
      getUserInfo,
      MoreFilled,
    };
  },
};
</script>
