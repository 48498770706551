import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "all-page" }
const _hoisted_2 = { class: "tool-bar" }
const _hoisted_3 = { class: "title-text" }
const _hoisted_4 = { style: {"display":"flex","width":"100%","gap":"20px","align-items":"baseline"} }
const _hoisted_5 = { style: {"flex-shrink":"0"} }
const _hoisted_6 = { style: {"display":"flex","justify-content":"center","width":"100%"} }
const _hoisted_7 = { class: "table-container" }
const _hoisted_8 = { style: {"display":"flex","align-items":"center","gap":"0.2rem","color":"#868693"} }
const _hoisted_9 = { style: {"color":"#868693"} }
const _hoisted_10 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementHeaderBar = _resolveComponent("ManagementHeaderBar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createVNode(_component_ManagementHeaderBar, { index: '/tag' })
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("tag")), 1),
      _createVNode(_component_el_button, {
        type: "primary",
        class: "add-btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.tagDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("addTag")), 1)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.tagDialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.tagDialogVisible) = $event)),
      title: _ctx.$t('addTag'),
      width: "500",
      style: {"z-index":"99999"}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('tagName')), 1),
          _createVNode(_component_el_input, {
            modelValue: $setup.newTagName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.newTagName) = $event)),
            placeholder: _ctx.$t('inputMsg'),
            style: {"width":"100%","margin-bottom":"1rem"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $setup.handleTagSubmit,
            class: "add-btn"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('submit')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createElementVNode("section", _hoisted_7, [
      _createVNode(_component_el_table, {
        data: $setup.tableData,
        style: {"width":"100%","--el-color-primary":"#f36633"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "Tag.TagName",
            label: _ctx.$t('tag')
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('CreatedBy')
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_el_avatar, {
                  src: 'data:image/jpeg;base64,' + $setup.findDataById(scope.row.Tag.TagId)!.User.Avatar,
                  size: 24,
                  class: "custom-avatar"
                }, null, 8, ["src"]),
                _createTextVNode(_toDisplayString($setup.findDataById(scope.row.Tag.TagId)!.User.UserName), 1)
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "Tag.CreateTime",
            label: _ctx.$t('createTime')
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", _hoisted_9, _toDisplayString($setup.formatTimestamp($setup.findDataById(scope.row.Tag.TagId)!.Tag.CreatedOn)), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('action'),
            width: "200"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_icon, {
                onClick: () => $setup.handleRowDelete(scope.row.Tag.TagId),
                style: {"cursor":"pointer"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Delete)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _createElementVNode("footer", _hoisted_10, [
      _createVNode(_component_el_pagination, {
        small: "",
        background: "",
        layout: "prev, pager, next",
        "page-count": $setup.totalPageCount,
        onCurrentChange: $setup.handleCurrentPageChange,
        style: {"--el-color-primary":"#f36633"}
      }, null, 8, ["page-count", "onCurrentChange"]),
      _createVNode(_component_el_select, {
        class: "select-number",
        modelValue: $setup.pageNum,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.pageNum) = $event)),
        onChange: $setup.handlePageNumChange
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([10, 20, 30, 40, 50], (item) => {
            return _createVNode(_component_el_option, {
              key: item,
              label: item + '/page',
              value: item
            }, null, 8, ["label", "value"])
          }), 64))
        ]),
        _: 1
      }, 8, ["modelValue", "onChange"])
    ])
  ]))
}