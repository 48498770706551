import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

setElementStyle() //初始化主题色
function setElementStyle() {
  const el = document.documentElement
  el.style.setProperty('--el-color-primary', '#f36633')
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})