export default {
  loginBtn: "会社アカウントでサインイン",
  welcomeMainMsg: "アイデアボックスへようこそ",
  welcomeSubMsg:
    "ここはあなたのアイデアのための場所です。アイデアを提出したり、他の人と協力したり、進捗状況を確認したり、すでに完了したプロジェクトを見たりすることができます。",
  submitIdea: "アイデアを提出する",
  andThen: "それから",
  andThenMsg: "他の人のアイデアを見て、アドバイスをすることもできます。",
  new: "新しい",
  discussion: "ディスカッション",
  building: "建設中",
  playground: "プレイグラウンド",
  pilot: "パイロット",
  pilotLower: "パイロット",
  workInProgress: "進行中の作業",
  updates: "アップデート",
  viewMore: "詳細を見る",
  aboutGSK: "GSKについて",
  aboutGSKMsg: "GSK plc. 全著作権所有",
  idea: "アイデア",
  submitYourIdea: "アイデアを提出する",
  title: "タイトル",
  imgCondition:
    "JPG、JPEG、PNG、またはGIF - ファイルサイズ制限5MB <br> 推奨サイズ：320x240px以上",
  chooseImg: "画像を選択する",
  chooseImgAgain: "画像を再選択する",
  description: "説明",
  attachments: "添付ファイル",
  drag: "ドラッグアンドドロップ - コンピュータを閲覧",
  mobileUploadMsg: "モバイルデバイスからアップロード",
  cancel: "キャンセル",
  home: "ホーム",
  submit: "提出",
  browse: "参照",
  allIdeas: "すべてのアイデア",
  showMyIdeas: "自分のアイデアを表示",
  searchAnything: "何でも検索...",
  operation: "操作",
  status: "ステータス",
  department: "部門",
  owner: "所有者",
  clear: "クリア",
  collected: "収集済み",
  supported: "サポート済み",
  commented: "コメント済み",
  comments: "コメント",
  submitted: "提出済み",
  followIdea: "アイデアをフォローする",
  unfollowIdea: "アイデアのフォローを解除する",
  users: "ユーザー",
  yourSuggestions: "ご意見",
  yourSuggestionsMsg: "ご意見をお聞かせください",
  viewProfile: "プロフィールを表示",
  job: "職種",
  ideas: "アイデア",
  ideasUpper: "アイデア",
  noSubmissions: "提出されたアイデアはありません。",
  myIdeas: "私のアイデア",
  myComments: "私のコメント",
  firstName: "名",
  lastName: "姓",
  email: "メール",
  oldPassword: "旧パスワード",
  newPassword: "新しいパスワード",
  confirmPassword: "パスワードを確認",
  passwordMsg: "新しいパスワードが一致しません。",
  userPool: "ユーザープール",
  ideasManagement: "アイデア管理",
  createTime: "作成日時",
  lastUpdate: "最終更新",
  detail: "詳細",
  action: "操作",
  searchIdeaName: "アイデア名を検索...",
  commentsDetail: "コメントの詳細",
  reply: "返信",
  replyMsg: "ここにコメントを入力してください...",
  onlyAdmin: "管理者のみ",
  manageAuthority: "権限の管理",
  submitLower: "提出",
  edit: "編集",
  delete: "削除",
  confirm: "確認",
  applyMsg:
    "管理者が申請を確認したら、メールで通知されます。<br />管理者権限の申請を続行しますか？",
  adminUser: "管理者ユーザー",
  playgroundLower: "プレイグラウンド",
  followIdeaMsg: "アイデアをフォローし、週次の進捗報告をメールで受け取る。",
  userName: "ユーザー名",
  lastLoginTime: "最終ログイン時刻",
  creativeIdeas: "クリエイティブなアイデア",
  approve: "承認",
  reject: "拒否",
  logout: "ログアウト",
  noCommentData: "コメントデータなし",
  justNow: "たった今",
  minute: "分",
  minutes: "分",
  hour: "時間",
  hours: "時間",
  day: "日",
  days: "日",
  week: "週間",
  weeks: "週間",
  month: "月",
  months: "月",
  year: "年",
  years: "年",
  ago: "前",
  CreatedBy: "作成者",
  Collects: "コレクション",
  Likes: "いいね",
  reset: "リセット",
  ideaStatistics: "アイデア統計",
  allTime: "全期間",
  withinayear: "1年以内",
  withinsixmonths: "6ヶ月以内",
  sum: "合計",
  exportExcel: "Excelエクスポート",
  warning: "警告",
  Warning: "警告",
  warningmsg: "この操作は永久的な削除を引き起こします。続行しますか？",
  deletesuccess: "削除成功",
  deletecancel: "削除キャンセル",
  submit_warning: "個人情報やGSK機密データのアップロードはご遠慮願います。",
  category: "カテゴリー",
  tag: "タグ",
  categoryMgt: "カテゴリー管理",
  tagMgt: "タグ管理",
  addCategory: "カテゴリーを追加",
  addTag: "タグを追加",
  categoryName: "カテゴリー名",
  inputMsg: "入力してください",
  categoryExist: "カテゴリーはすでに存在します",
  tagExist: "タグはすでに存在します",
  disbale: "無効",
  disableSuccess: "無効化完了",
  disableMsg: "無効化後は元に戻せません。無効化しますか？",
  tagName: "タグ名",
  selectMsg: "選択してください",
  loading: "読み込み中...",
  addSuccess: "追加完了",
};