import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/index/Rectangle.png'
import _imports_1 from '@/assets/images/index/Rectangle_bottom.png'


const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "banner-container dialog-parent" }
const _hoisted_4 = { class: "banner-text-container" }
const _hoisted_5 = { class: "banner-title" }
const _hoisted_6 = { class: "banner-description-container" }
const _hoisted_7 = {
  class: "banner-description",
  style: {"margin-top":"20px"}
}
const _hoisted_8 = { class: "submitbtn-container" }
const _hoisted_9 = { class: "nav-container" }
const _hoisted_10 = { class: "nav-text-firstline" }
const _hoisted_11 = { class: "nav-text-secondline" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "card-title" }
const _hoisted_14 = { class: "cardinfo-count-container" }
const _hoisted_15 = { class: "grey-container" }
const _hoisted_16 = { class: "grey-left-container" }
const _hoisted_17 = { class: "newscard-group-container" }
const _hoisted_18 = { class: "grey-right-container" }
const _hoisted_19 = { class: "action-bar" }
const _hoisted_20 = { class: "action-bar-text" }
const _hoisted_21 = { class: "footer" }
const _hoisted_22 = { class: "footer-firstline" }
const _hoisted_23 = { class: "footer-lastline" }
const _hoisted_24 = { class: "footer_bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_NewsCard = _resolveComponent("NewsCard")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_UpdatesCard = _resolveComponent("UpdatesCard")!
  const _component_FooterBar = _resolveComponent("FooterBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_HeaderBar)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "rectangle",
        src: _imports_0
      }, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("welcomeMainMsg")), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("welcomeSubMsg")), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_el_button, {
            class: "submit-btn",
            onClick: $setup.navToSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("submitIdea")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("andThen")), 1),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("andThenMsg")), 1),
      _createVNode(_component_el_row, { class: "card-group-container" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.statusOptions, (cardInfo) => {
            return (_openBlock(), _createBlock(_component_el_col, {
              xs: { span: 10 },
              sm: { span: 10 },
              lg: { span: 5 },
              xl: { span: 4 },
              key: cardInfo.StatusCode
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "card-wrapper",
                  onClick: ($event: any) => ($setup.navToBrowse(cardInfo.StatusCode))
                }, [
                  _createVNode(_component_el_card, {
                    class: "card-container",
                    style: _normalizeStyle({
              backgroundImage: $setup.backgroundImage(cardInfo.StatusCode).value,
            })
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", _hoisted_13, _toDisplayString($setup.getStatusText(cardInfo.StatusCode)), 1),
                      _createElementVNode("div", _hoisted_14, _toDisplayString(cardInfo.StatusCount), 1)
                    ]),
                    _: 2
                  }, 1032, ["style"])
                ], 8, _hoisted_12)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("section", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_el_menu, {
          "default-active": "1",
          mode: "horizontal",
          ellipsis: false,
          class: "custom-menu",
          onSelect: $setup.handleIdeaStatusSelect
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, { index: "1" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("new")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("discussion")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "3" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("building")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, { index: "4" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("pilot")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onSelect"]),
        _createElementVNode("div", _hoisted_17, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredIdeaList.slice(0, $setup.IdeaPageSize), (Idea, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_NewsCard, { ideaItem: Idea }, null, 8, ["ideaItem"])
            ]))
          }), 128))
        ]),
        (
          $setup.currentIdeaStatus === 1
            ? $setup.newIdeaList.length > $setup.IdeaPageSize
            : $setup.currentIdeaStatus === 2
              ? $setup.workInProgressIdeaList.length > $setup.IdeaPageSize
              : $setup.playgroundIdeaList.length > $setup.IdeaPageSize
        )
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              round: "",
              plain: "true",
              class: "viewMoreBtnRow",
              style: {"margin-left":"2rem"},
              onClick: $setup.viewMoreIdea
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("viewMore")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("updates")), 1),
          _createVNode(_component_el_badge, {
            value: $setup.updateList.length,
            style: {"margin-left":"0.75rem"}
          }, null, 8, ["value"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.updateList.slice(0, $setup.UpdatePageSize), (updateItem, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_UpdatesCard, { updatesItems: updateItem }, null, 8, ["updatesItems"])
          ]))
        }), 128)),
        ($setup.updateList.length > $setup.UpdatePageSize)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              round: "",
              plain: "true",
              class: "viewMoreBtnRow",
              onClick: $setup.viewMoreUpdate
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("viewMore")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: _imports_1,
      style: {"width":"100%"}
    }, null, -1)),
    _createElementVNode("section", _hoisted_21, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("aboutGSK")), 1),
        _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("aboutGSKMsg")), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_24, [
      _createVNode(_component_FooterBar, { activeItem: 1 })
    ])
  ]))
}