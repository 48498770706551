import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"
import _imports_0 from '@/assets/images/logo.png'
import _imports_1 from '@/assets/icons/caret-down.svg'
import _imports_2 from '@/assets/icons/gear.svg'
import _imports_3 from '@/assets/icons/log-out.svg'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "left-container" }
const _hoisted_3 = { class: "right-container" }
const _hoisted_4 = { class: "dropdown-container" }
const _hoisted_5 = { class: "dropdown-link" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "dialog-footer" }
const _hoisted_8 = { class: "avatar-pop-container" }
const _hoisted_9 = { class: "avatar-pop-name" }
const _hoisted_10 = { class: "logout-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        style: {"cursor":"pointer","height":"2.38rem","width":"11.1rem"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogoClick && _ctx.handleLogoClick(...args)))
      }),
      _createVNode(_component_el_menu, {
        "default-active": _ctx.index,
        mode: "horizontal",
        ellipsis: false,
        class: "custom-menu",
        router: "true"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, { index: "/" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("home")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "/submit" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("submit")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_menu_item, { index: "/browse" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("browse")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["default-active"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_dropdown, { onCommand: _ctx.handleLanguageChange }, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownOptions, (dropdownOption, dropdownOptionIndex) => {
                  return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: dropdownOptionIndex,
                    command: {
                  label: dropdownOption.label,
                  value: dropdownOption.value,
                }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(dropdownOption.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["command"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("button", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.dropdownValue), 1),
              _cache[5] || (_cache[5] = _createElementVNode("img", { src: _imports_1 }, null, -1))
            ])
          ]),
          _: 1
        }, 8, ["onCommand"])
      ]),
      (_ctx.getUserInfo()!.graphUserInfo!.authority == 1)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            class: "gear-container",
            onClick: _ctx.handleGearClick
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("img", { src: _imports_2 }, null, -1)
            ])),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.getUserInfo()!.graphUserInfo!.authority === 0 &&
        _ctx.getUserInfo()!.graphUserInfo!.pendingAdmit === false
      )
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            class: "gear-container",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.applyAdmin = true))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Avatar)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
        _createVNode(_component_el_dialog, {
          modelValue: _ctx.applyAdmin,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.applyAdmin) = $event)),
          title: "Tips",
          width: "30%",
          style: {"z-index":"99999999"}
        }, {
          footer: _withCtx(() => [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_el_button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.applyAdmin = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.handleAdminRequestConfirm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("confirm")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.$t('applyMsg'),
              class: "apply-msg"
            }, null, 8, _hoisted_6)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])),
      _createVNode(_component_el_popover, {
        width: 160,
        "popper-style": "box-shadow: 0px 2px 10px 0px rgba(72, 72, 81, 0.10);padding:0",
        placement: "bottom-end"
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_el_avatar, {
            src: 'data:image/jpeg;base64,' + _ctx.getUserInfo()!.graphUserInfo!.avatar,
            class: "custom-avatar",
            style: {"margin-left":"1.5rem"},
            size: 40
          }, null, 8, ["src"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", {
              style: {"cursor":"pointer","padding-top":"1rem","padding-bottom":"1rem"},
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleProfileClick && _ctx.handleProfileClick(...args)))
            }, [
              _createVNode(_component_el_avatar, {
                src: 'data:image/jpeg;base64,' + _ctx.getUserInfo()!.graphUserInfo!.avatar,
                class: "custom-avatar",
                size: 64
              }, null, 8, ["src"]),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.getUserInfo()?.graphUserInfo!.displayName
                    ? _ctx.getUserInfo()?.graphUserInfo!.displayName
                    : _ctx.getUserInfo()!.graphUserInfo!.userName), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_button, {
                class: "logout-btn",
                onClick: _ctx.handleLogoutClick,
                style: {"--el-color-primary":"#f36633"}
              }, {
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createElementVNode("img", {
                    src: _imports_3,
                    style: {"margin-right":"0.75rem"}
                  }, null, -1)),
                  _createTextVNode(_toDisplayString(_ctx.$t("logout")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}