<template>
    <div class="all-page">
        <!-- Header导航栏部分 -->
        <header>
            <ManagementHeaderBar :index="'/tag'" />
        </header>
        <section class="tool-bar">
            <span class="title-text">{{ $t("tag") }}</span>
            <el-button type="primary" class="add-btn" @click="tagDialogVisible = true">
                {{ $t("addTag") }}
            </el-button>
        </section>
        <el-dialog v-model="tagDialogVisible" :title="$t('addTag')" width="500" style="z-index: 99999;">

            <div style="display: flex; width: 100%;gap: 20px; align-items: baseline">
                <div style="flex-shrink: 0;">{{ $t('tagName') }}</div>
                <el-input v-model="newTagName" :placeholder="$t('inputMsg')"
                    style="width: 100%; margin-bottom: 1rem;" />
            </div>
            <div style="display: flex; justify-content: center; width: 100%;">
                <el-button type="primary" @click="handleTagSubmit" class="add-btn">{{ $t('submit') }}</el-button>
            </div>
        </el-dialog>

        <section class="table-container">
            <el-table :data="tableData" style="width: 100%; --el-color-primary: #f36633">
                <el-table-column prop="Tag.TagName" :label="$t('tag')">
                </el-table-column>
                <el-table-column :label="$t('CreatedBy')">
                    <template #default="scope">
                        <div style="
                  display: flex;
                  align-items: center;
                  gap: 0.2rem;
                  color: #868693;
                ">
                            <el-avatar :src="'data:image/jpeg;base64,' + findDataById(scope.row.Tag.TagId)!.User.Avatar"
                                :size="24" class="custom-avatar"></el-avatar>{{
                                    findDataById(scope.row.Tag.TagId)!.User.UserName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="Tag.CreateTime" :label="$t('createTime')">
                    <template #default="scope">
                        <span style="color: #868693">{{
                            formatTimestamp(findDataById(scope.row.Tag.TagId)!.Tag.CreatedOn)
                        }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('action')" width="200">
                    <template #default="scope">
                        <el-icon @click="() => handleRowDelete(scope.row.Tag.TagId)" style="cursor: pointer">
                            <Delete />
                        </el-icon>
                    </template>
                </el-table-column>
            </el-table>
        </section>

        <footer class="footer">
            <el-pagination small background layout="prev, pager, next" :page-count="totalPageCount"
                @current-change="handleCurrentPageChange" style="--el-color-primary: #f36633" />
            <!-- Footer 选择个数部分 -->
            <el-select class="select-number" v-model="pageNum" @change="handlePageNumChange">
                <el-option v-for="item in [10, 20, 30, 40, 50]" :key="item" :label="item + '/page'"
                    :value="item"></el-option>
            </el-select>
        </footer>
    </div>
</template>

<style scoped>
.all-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}

.tool-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4.5rem;
}

.title-text {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.select-number {
    width: 10rem;
    --el-color-primary: #f36633;
}

.table-container {
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 10rem;
}

.footer {
    width: 100%;
    position: fixed;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-sizing: border-box;
}

.custom-avatar {
    margin-right: 0.5rem;
    border: 2px solid rgba(174, 174, 183, 0.2);
    flex-shrink: 0;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    background-color: #f36633;
    border-color: #f36633;
}

.add-btn {
    color: #fff;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    --el-button-bg-color: #f36633 !important;
    --el-button-border-color: #f36633 !important;
    --el-button-hover-bg-color: #376f7b !important;
    --el-button-hover-text-color: white !important;
    --el-button-hover-border-color: #376f7b !important;
    --el-button-active-bg-color: #376f7b !important;
    --el-button-active-border-color: #376f7b !important;
}
</style>

<script lang="ts">
import { onMounted, inject, ref } from 'vue';
import { AxiosInstance } from "axios";
import ManagementHeaderBar from '@/components/ManagementHeaderBar.vue'
import { formatTimestamp } from "@/utils/formatTimestamp";
import { ElMessage, ElMessageBox } from 'element-plus';
import { useI18n } from "vue-i18n";
import { Delete } from "@element-plus/icons-vue";

export default {
    name: 'TagManagementView',
    components: {
        ManagementHeaderBar
    },
    setup() {
        const { t } = useI18n();

        const totalPageCount = ref(null);
        interface TagData {
            Tag: {
                TagId: number;
                TagName: string;
                CreatedOn: string;
                CreatedBy: number | null;
                ModifiedOn: string | null;
                ModifiedBy: number | null;
                State: number;
            };
            User: {
                UserId: number;
                UserName: string;
                Avatar: string;
            }
        }

        const tableData = ref<TagData[]>([]);
        const pageNum = ref(10);
        const currentPage = ref(1);
        const axiosInstance = inject("axiosInstance") as AxiosInstance;
        const tagDialogVisible = ref(false);
        const newTagName = ref("");


        onMounted(() => {
            GetTagList()
        })


        const GetTagList = async () => {
            axiosInstance.get("api/Tag/GetTagList", {
                params: {
                    PageSize: pageNum.value,
                    PageIndex: currentPage.value,
                }
            }).then((res) => {
                tableData.value = res.data.Data;
                console.log(tableData.value);
                totalPageCount.value = res.data.Data[0].PageCount;
            });
        }

        const handleCurrentPageChange = (val: number) => {
            currentPage.value = val;
            GetTagList();
        };

        const handlePageNumChange = () => {
            GetTagList();
        };

        const findDataById = (TagId: number) => {
            return tableData.value.find((data) => data.Tag.TagId === TagId);
        };

        const handleRowDelete = (TagId: number) => {
            ElMessageBox.confirm(
                t('warningmsg'),
                t('Warning'),
                {
                    confirmButtonText: t('confirm'),
                    cancelButtonText: t('cancel'),
                    type: 'warning',
                }
            ).then(() => {
                DeleteTag(TagId);
            }).catch(() => {
                GetTagList();
            });
        }

        const DeleteTag = (TagId: number) => {
            axiosInstance.post('api/Tag/DeleteTag', {
                TagId: TagId
            }).then((res) => {
                console.log(res)
                ElMessage.success(t('deletesuccess'));
                GetTagList();
            });
        }

        const handleTagSubmit = () => {
            axiosInstance.post("api/Tag/AddTag", {
                TagName: newTagName.value
            }).then((res) => {
                if (res.data.Data === -1) {
                    ElMessage.error(t('tagExist'));
                }
                else {
                    ElMessage.success(t('addSuccess'));
                }
                GetTagList();
                tagDialogVisible.value = false;
            });
        }

        return {
            totalPageCount,
            tableData,
            pageNum,
            currentPage,
            GetTagList,
            tagDialogVisible,
            handleCurrentPageChange,
            handlePageNumChange,
            formatTimestamp,
            findDataById,
            newTagName,
            handleTagSubmit,
            handleRowDelete
        }
    }
}

</script>