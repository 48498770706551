<template>
  <div class="header">
    <div class="left-container">
      <img src="@/assets/images/logo.png" style="cursor: pointer" @click="handleLogoClick" />
      <el-menu :default-active="index" mode="horizontal" :ellipsis="false" class="custom-menu" router="true">
        <el-menu-item index="/userpool">{{ $t("userPool") }}</el-menu-item>
        <el-menu-item index="/management">{{
          $t("ideasManagement")
        }}</el-menu-item>
        <el-menu-item index="/category">{{ $t("categoryMgt") }}</el-menu-item>
        <el-menu-item index="/tag">{{ $t("tagMgt") }}</el-menu-item>
        <el-menu-item index="/stastics">{{
          $t("ideaStatistics")
        }}</el-menu-item>
      </el-menu>
    </div>
    <div class="right-container">
      <div class="dropdown-container">
        <el-dropdown @command="handleLanguageChange">
          <button class="dropdown-link">
            {{ dropdownValue }}<img src="@/assets/icons/caret-down.svg" />
          </button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="(dropdownOption, dropdownOptionIndex) in dropdownOptions"
                :key="dropdownOptionIndex" :command="{
                  label: dropdownOption.label,
                  value: dropdownOption.value,
                }">{{ dropdownOption.label }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-popover :width="160" popper-style="box-shadow: 0px 2px 10px 0px rgba(72, 72, 81, 0.10);padding:0"
        placement="bottom-end">
        <template #reference>
          <el-avatar :src="'data:image/jpeg;base64,' + userInfo?.graphUserInfo!.avatar" class="custom-avatar"
            style="margin-left: 1.5rem" :size="40" />
        </template>
        <template #default>
          <div class="avatar-pop-container">
            <div style="cursor: pointer; padding-top: 1rem; padding-bottom: 1rem" @click="handleProfileClick">
              <el-avatar :src="'data:image/jpeg;base64,' + userInfo?.graphUserInfo!.avatar
                " class="custom-avatar" :size="64">
              </el-avatar>
              <div class="avatar-pop-name">
                {{ userInfo?.graphUserInfo!.displayName }}
              </div>
            </div>

            <div class="logout-row">
              <el-button class="logout-btn" @click="handleLogoutClick" style="--el-color-primary: #f36633"><img
                  src="@/assets/icons/log-out.svg" style="margin-right: 0.75rem" />{{ $t("logout") }}</el-button>
            </div>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<style scoped>
.header {
  padding: 0px 2%;
  display: flex;
  min-height: 4rem;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 9;
}

.left-container {
  display: flex;
  align-items: center;
  width: auto;
}

.custom-menu {
  margin-left: 10%;
  background-color: transparent;
  margin-top: 5px;
}

:deep(.el-input__wrapper) {
  background-color: transparent;
  box-shadow: none;
}

:deep(.el-input__inner) {
  color: #484851;
  text-align: center;
  font-family: GSK Precision;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  /* 150% */
}

:deep(.el-select) {
  font-weight: 400;
  --el-select-input-focus-border-color: #376f7b;
}

.el-dropdown__item {
  color: #3c3c43 !important;
}

:deep .custom-menu .el-menu-item {
  color: #868693 !important;
  font-weight: 700;
  line-height: 2.5rem;
}

.el-dropdown__popper {
  --el-dropdown-menuItem-hover-fill: #f6f6f6 !important;
  --el-dropdown-menuItem-hover-color: #3c3c43 !important;
}

.custom-menu .el-menu-item:hover,
.custom-menu .el-menu-item:focus,
.custom-menu .el-menu-item:active {
  background-color: transparent !important;
  color: black !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  color: black !important;
  border-color: black !important;
}

.el-menu {
  --el-menu-border-color: transparent;
}

.custom-menu .el-menu-item.is-active {
  color: inherit;
}

.right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.el-avatar {
  border: 2px solid rgba(174, 174, 183, 0.2);
}

.dropdown-container {
  display: flex;
  align-items: center;
  color: #484851;
  text-align: center;
  font-size: 1rem;
}

.gear-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 6px;
  border: 1px solid #c9c9cf;
  background-color: transparent;
  margin-left: 1rem;
}

.gear-container:hover,
.gear-container:active {
  border: 1px solid #376f7b;
  background: rgba(55, 111, 123, 0.1);
}

.gear-container:focus {
  border: 1px solid #376f7b;
  background-color: transparent;
}

.gear {
  width: 1.25rem;
  height: 1.25rem;
}

.dropdown-link {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  text-align: center;
  background-color: transparent;
  border-radius: 6px;
  border-color: transparent;
  font-family: GSK Precision;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  height: 2rem;
}

.dropdown-link:hover,
.dropdown-link:active,
.dropdown-link:focus {
  border: 1px solid #376f7b;
  background: rgba(55, 111, 123, 0.1);
}

.avatar-pop-container {
  padding-bottom: 0.5rem;
  border-radius: 8px;
  background: #fff;
  text-align: center;
}

.avatar-pop-name {
  margin-top: 0.5rem;
  color: #3c3c43;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5%;
}

.logout-row {
  border-top: 1px solid #c9c9cf;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.logout-btn {
  height: 1.875rem;
  width: 100%;
  background-color: white;
  border: none;
  color: #868693;
  text-align: center;
  font-family: GSK Precision;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 157.143%;
  --el-button-border-color: #c9c9cf !important;
  --el-button-hover-bg-color: white !important;
  --el-button-active-bg-color: white !important;
}

.custom-avatar {
  cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  .custom-menu {
    margin-left: 5%;
  }
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { getUserInfo, saveUserInfo } from "@/state/UserInfo";

export default defineComponent({
  name: "HeaderBar",
  props: {
    index: { type: String, default: "/" },
  },

  setup() {
    const dropdownValue = ref("English");
    const { locale } = useI18n();
    const dropdownOptions = reactive([
      {
        value: "en",
        label: "English",
      },
      {
        value: "zh",
        label: "简体中文",
      },
      {
        value: "jp",
        label: "にほんご",
      },
    ]);
    let matchedOption = dropdownOptions.find(
      (option) => option.value === locale.value
    );
    if (matchedOption) {
      dropdownValue.value = matchedOption.label;
    }
    const router = useRouter();
    const userInfo = getUserInfo();
    const handleLanguageChange = (e: any) => {
      console.log(e);
      dropdownValue.value = e.label;
      locale.value = e.value;
    };

    const handleLogoutClick = () => {
      router.push("/login");
      saveUserInfo(null);
    };

    const handleProfileClick = () => {
      router.push("/profile");
    };

    const handleLogoClick = () => {
      router.push("/");
    };

    return {
      dropdownValue,
      dropdownOptions,
      userInfo,
      handleLanguageChange,
      handleLogoutClick,
      handleProfileClick,
      handleLogoClick,
    };
  },
});
</script>
